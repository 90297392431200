import { Component, Inject, HostListener} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from './../common.service';
import { Globals } from '../globals';
import { DataSharingService } from '../data-share.service';

import * as $ from 'jquery' ;

export interface DialogData {
  dish: any;
  business: any;
  CartDish: any;
  indexCart: any;
  type: Number;
}

@Component({
  selector: 'app-dish',
  templateUrl: './dish.component.html',
  styleUrls: ['./dish.component.css'],
})
export class DishComponent {
  public globals: Globals;
  options = { autoHide: true, scrollbarMinSize: 100 };
  public dish: any;
  public businessDetails: any;
  public dishTotal: any;
  public CartDish: any;
  public indexcart: any;
  public BackupCart: any;
  public language: any;
  public suggestProduct: any = [];
  public dishShare: any;
  public orderType: any;
  public dataType: any;
  constructor(
    private comservice: CommonService,
    public snackBar: MatSnackBar,
    public dataSharingService: DataSharingService,
    public dialogRef: MatDialogRef < DishComponent >,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
    this.dish = new Array();
    let dish;
    dish = JSON.stringify(data.dish);
    this.dish = JSON.parse(dish);

    this.dataType = data.type;

    if (data.business.buyone_getone_status) {
     
      if (this.dataType == 2) {
        this.dishTotal = 0.00;
      }else{
        this.dishTotal = this.dish.price;
      }
    }else{
      this.dishTotal = this.dish.price;
    }


    
    this.businessDetails = data.business;
    this.orderType = this.comservice.get('orderType');
    this.dishShare = {
      url: this.globals.SITE_URL + '/' + this.businessDetails.slug,
      description: this.dish.name
    };

    setTimeout(function(){
      let content_div = document.getElementsByClassName('product_scroll_div');
      if(content_div.length > 0){
        content_div[0].scrollTop = 0;
      }
  },260)

    
    this.indexcart = new Object();

    let CartDish;
    CartDish = data.CartDish;
    if (CartDish !== undefined) {
      this.CartDish = CartDish;
    } else {
      this.CartDish = new Array();
    }

    if (data.type === 1) {
      this.indexcart = data.indexCart;
      let qty;
      qty = parseInt(this.CartDish[this.indexcart.index].dish[this.indexcart.dishindex].data[this.indexcart.dishdataindex].quantity, 10);
      this.dishTotal = this.indexcart.dishTotal / qty;
      this.dishTotal = parseFloat(this.dishTotal).toFixed(2);
    }
  }
  checkProductOption(setIndex: any, optionIndex: any, choiceIndex: any) {
    if (this.dish.sets[setIndex].options[optionIndex].type === 0) {
      for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].choice.length; i++ ) {
        this.dish.sets[setIndex].options[optionIndex].choice[i].checked = false;
      }

      this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].checked = true;

      this.CheckType0(setIndex, optionIndex);
      for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].coditions.length; i++) {
        if (this.dish.sets[setIndex].options[optionIndex].coditions[i].choice === this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].id) {
          for (let j = 0; j < this.dish.sets[setIndex].options.length; j++) {
            if (this.dish.sets[setIndex].options[j].id === this.dish.sets[setIndex].options[optionIndex].coditions[i].rest_op_id) {
              this.dish.sets[setIndex].options[j].show = true;
            }
          }
        }
      }
    }

    if (this.dish.sets[setIndex].options[optionIndex].type === 1) {
      let trues = 0;
      if (this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].checked === true) {
        this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].checked = false;
        if(this.businessDetails.choose_product_option_with_qty == 1){
          $('#display_qty_' + setIndex + "_" + optionIndex + "_" + choiceIndex).css('display','none');
          this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].qty = 1;
        }
      } else {
        this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].checked = true;
        if(this.businessDetails.choose_product_option_with_qty == 1){
          $('#display_qty_' + setIndex + "_" + optionIndex + "_" + choiceIndex).css('display','flex');
          }
      }
      for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].choice.length; i++) {
        if(this.businessDetails.choose_product_option_with_qty == 1){
          if (this.dish.sets[setIndex].options[optionIndex].choice[i].checked === true) {
            trues = trues + this.dish.sets[setIndex].options[optionIndex].choice[i].qty;
          }
  
        }else{
          if (this.dish.sets[setIndex].options[optionIndex].choice[i].checked === true) {
            trues++;
          }
        }
      }
      if (trues > this.dish.sets[setIndex].options[optionIndex].max) {
        this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].checked = false;

        if(this.businessDetails.choose_product_option_with_qty == 1){
          $('#display_qty_' + setIndex + "_" + optionIndex + "_" + choiceIndex).css('display','none');
          this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].qty = 1;
        }

        this.snackBar.open(this.language.MAX_NUMBER_SELECTION + this.dish.sets[setIndex].options[optionIndex].max, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }

      for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].coditions.length; i++) {
        if (this.dish.sets[setIndex].options[optionIndex].coditions[i].choice === this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].id) {
          if (this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].checked === true) {
            for (let j = 0; j < this.dish.sets[setIndex].options.length; j++) {
              if (this.dish.sets[setIndex].options[j].id === this.dish.sets[setIndex].options[optionIndex].coditions[i].rest_op_id) {
                this.dish.sets[setIndex].options[j].show = true;
              }
            }
          }
        }
      }

      if (this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].checked === false) {
        let rest_op_id = 0;
        for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].coditions.length; i++) {
          if (this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].id === this.dish.sets[setIndex].options[optionIndex].coditions[i].choice) {
            rest_op_id = this.dish.sets[setIndex].options[optionIndex].coditions[i].rest_op_id;
          }
        }
        if (rest_op_id !== 0) {
          let count = 0;
          for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].coditions.length; i++) {
            if (this.dish.sets[setIndex].options[optionIndex].coditions[i].rest_op_id === rest_op_id && this.dish.sets[setIndex].options[optionIndex].coditions[i].choice !== this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].id) {
              for (let j = 0; j < this.dish.sets[setIndex].options[optionIndex].choice.length; j++) {
                if (this.dish.sets[setIndex].options[optionIndex].choice[j].id === this.dish.sets[setIndex].options[optionIndex].coditions[i].choice && this.dish.sets[setIndex].options[optionIndex].choice[j].checked === true) {
                  count++;
                }
              }
            }
          }

          if (count === 0) {
            this.CheckType1(setIndex, optionIndex, choiceIndex, rest_op_id);
          }
        }
      }
    }

    //this.dishTotal = this.dish.price;
    if (this.businessDetails.buyone_getone_status) {
      if (this.dataType == 2) {
        this.dishTotal = 0.00;
      }else{
        this.dishTotal = this.dish.price;
      }
    }else{
      this.dishTotal = this.dish.price;
    }

    if(this.businessDetails.choose_product_option_with_qty == 1){
      for (let k = 0; k < this.dish.sets.length; k++) {
        for (let i = 0; i < this.dish.sets[k].options.length; i++) {
          if (this.dish.sets[k].options[i].show === true) {
            for (let j = 0; j < this.dish.sets[k].options[i].choice.length; j++) {
              if (this.dish.sets[k].options[i].choice[j].checked === true) {
                this.dishTotal = parseFloat(this.dishTotal) + (parseFloat(this.dish.sets[k].options[i].choice[j].price) * parseInt(this.dish.sets[k].options[i].choice[j].qty));
                this.dishTotal = parseFloat(this.dishTotal).toFixed(2);
              }
            }
          }
        }
      }
    }else{
        for (let k = 0; k < this.dish.sets.length; k++) {
          for (let i = 0; i < this.dish.sets[k].options.length; i++) {
            if (this.dish.sets[k].options[i].show === true) {
              for (let j = 0; j < this.dish.sets[k].options[i].choice.length; j++) {
                if (this.dish.sets[k].options[i].choice[j].checked === true) {
                  this.dishTotal = parseFloat(this.dishTotal) + parseFloat(this.dish.sets[k].options[i].choice[j].price);
                  this.dishTotal = parseFloat(this.dishTotal).toFixed(2);
                }
              }
            }
          }
        }
    }

  }


  CheckType1(setIndex: any, optionIndex: any, choiceIndex: any, rest_op_id: any) {
    let temp = 0;
    for (let i = 0; i < this.dish.sets[setIndex].options.length; i++) {
      if (this.dish.sets[setIndex].options[i].id === rest_op_id) {
        this.dish.sets[setIndex].options[i].show = false;
        temp = i;
        for (let k = 0; k < this.dish.sets[setIndex].options[i].choice.length; k++) {
          this.dish.sets[setIndex].options[i].choice[k].checked = false;
        }
        this.CheckType0(setIndex, temp);
      }
    }
  }
  CheckType0(setIndex: any, optionIndex: any) {
    let temp = 0;
    if (this.dish.sets[setIndex].options[optionIndex].coditions.length > 0) {
      for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].coditions.length; i++) {
        for (let j = 0; j < this.dish.sets[setIndex].options.length; j++) {
          if (this.dish.sets[setIndex].options[optionIndex].coditions[i].rest_op_id === this.dish.sets[setIndex].options[j].id) {
            this.dish.sets[setIndex].options[j].show = false;
            temp = j;
            for (let k = 0; k < this.dish.sets[setIndex].options[j].choice.length; k++) {
              this.dish.sets[setIndex].options[j].choice[k].checked = false;
            }
            this.CheckType0(setIndex, temp);
          }
        }
      }
    }
  }
  addToCart() {
    if (this.dish.sets !== undefined) {
      if (this.dish.sets.length > 0) {
        for (let i = 0; i < this.dish.sets.length; i++) {
          for (let j = 0; j < this.dish.sets[i].options.length; j++) {
            if (this.dish.sets[i].options[j].show === true) {
              if (this.dish.sets[i].options[j].type === 1) {
                let min = 0;
                for (let k = 0; k < this.dish.sets[i].options[j].choice.length; k++) {
                  if (this.dish.sets[i].options[j].choice[k].checked === true) {
                        if(this.businessDetails.choose_product_option_with_qty == 1){
                          min = min + this.dish.sets[i].options[j].choice[k].qty;
                        }else{
                          min++;
                        }
                  }
                }
                if (this.dish.sets[i].options[j].required === true && min === 0) {
                  this.snackBar.open(this.dish.sets[i].options[j].name + this.language.IS_REQUIRED, this.language.TOAST_OK, {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  return false;
                } else if (min < this.dish.sets[i].options[j].min) {
                  this.snackBar.open(this.dish.sets[i].options[j].name + this.language.MINIMUM_CHOICE_IS + this.dish.sets[i].options[j].min, this.language.TOAST_OK, {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  return false;
                }
              } else if (this.dish.sets[i].options[j].required === true && this.dish.sets[i].options[j].type === 0) {
                let min = 0;
                for (let k = 0; k < this.dish.sets[i].options[j].choice.length; k++) {
                  if (this.dish.sets[i].options[j].choice[k].checked === true) {
                    min++;
                  }
                }
                if (min === 0) {
                  this.snackBar.open(this.dish.sets[i].options[j].name + this.language.IS_REQUIRED, this.language.TOAST_OK, {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  return false;
                }
              }
            }
          }
        }
      }
    }

    let bDeatilsCloned;
    bDeatilsCloned = JSON.stringify(this.businessDetails);
    let bDeatils;
    bDeatils = JSON.parse(bDeatilsCloned);
    bDeatils.street = JSON.parse(bDeatils.street);
    bDeatils.type = JSON.parse(bDeatils.type);
    bDeatils.location = JSON.parse(bDeatils.location);
    bDeatils.is_img = JSON.parse(bDeatils.is_img);
    bDeatils.is_banner = JSON.parse(bDeatils.is_banner);
    bDeatils.cuisine = JSON.parse(bDeatils.cuisine);

    let business;
    business = {
      id: this.businessDetails.id,
      details: bDeatils,
      dish: new Array()
    };


    let dish;
    dish = {
      'id': this.dish.id,
      'category': this.dish.category,
      'quantity': 1,
      'data' : new Array()
    };

    let activeIngredients;
    activeIngredients = new Array();
    for (const ingre of this.dish.ingredientsarray) {
      if (ingre.status === false) {
        activeIngredients.push(ingre.name);
      }
    }
    let data: any;
    data = {
      'id': this.dish.id,
      'name': this.dish.name,
      'price': this.dish.price,
      'delprice': this.dish.delprice,
      'pickprice': this.dish.pickprice,
      'offerprice': this.dish.offerprice,
      'pofferprice': this.dish.pofferprice,
      'notes': this.dish.notes,
      'totalprice': this.dishTotal,
      'total': this.dishTotal,
      'is_img': this.dish.is_img,
      'img': this.dish.img,
      'spicy': this.dish.spicy,
      'spicyquantity': this.dish.spicyquantity,
      'veg': this.dish.veg,
      'nonveg': this.dish.nonveg,
      'ingredients': this.dish.ingredients,
      'ingredientsarray': this.dish.ingredientsarray,
      'activeIngredients': activeIngredients,
      'activeIngredientsText': activeIngredients.join(', '),
      'quantity': 1,
      'sets': new Array(),
      'relation': {
        'options': new Array(),
        'choices': new Array()
      },
      'free': {}
    };

    let optionsarray;
    optionsarray = new Array();
    let choicesarray;
    choicesarray = new Array();
    if (this.dish.sets !== undefined) {
      if (this.dish.sets.length > 0) {
        for (let i = 0; i < this.dish.sets.length; i++) {
          for (let j = 0; j < this.dish.sets[i].options.length; j++) {
            let options;
            options = {
              'id': this.dish.sets[i].options[j].id,
              'name': this.dish.sets[i].options[j].name,
              'choice': new Array(),
              'choiceText': ''
            };
            let choiceTextArray;
            choiceTextArray = new Array();
            for (let k = 0; k < this.dish.sets[i].options[j].choice.length; k++) {
              if (this.dish.sets[i].options[j].choice[k].checked === true) {
                let choice;
                if(this.businessDetails.choose_product_option_with_qty == 1){
                  choice = {
                    'id': this.dish.sets[i].options[j].choice[k].id,
                    'name': this.dish.sets[i].options[j].choice[k].name,
                    'price': this.dish.sets[i].options[j].choice[k].price * this.dish.sets[i].options[j].choice[k].qty,
                    'qty': this.dish.sets[i].options[j].choice[k].qty
                  };
                }else{
                  choice = {
                    'id': this.dish.sets[i].options[j].choice[k].id,
                    'name': this.dish.sets[i].options[j].choice[k].name,
                    'price': this.dish.sets[i].options[j].choice[k].price,
                    'qty': this.dish.sets[i].options[j].choice[k].qty
                  };
                }

                if(this.businessDetails.choose_product_option_with_qty == 1){
                  choiceTextArray.push(this.dish.sets[i].options[j].choice[k].name + "  x  " + this.dish.sets[i].options[j].choice[k].qty);

                }else{
                  choiceTextArray.push(this.dish.sets[i].options[j].choice[k].name);

                }
                choicesarray.push(choice.id);
                options.choice.push(choice);
              }
            }
            options.choiceText = choiceTextArray.join(', ');
            if (options.choice.length > 0) {
              optionsarray.push(options.id);
              data.sets.push(options);
            }
          }
        }
      }
    }
    if (data.sets.length > 0) {
      data.relation = {
        'options': optionsarray,
        'choices': choicesarray
      };
    }

    let parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    if (parent_index === -1) {
      this.CartDish.push(business);
    }
    parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);

    if (this.businessDetails.buyone_getone_status) {
     
      if (this.dataType == 2) {
        let parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
        let dish_index = this.CartDish[parent_index].dish.length - 1;

        if (parseFloat(this.CartDish[parent_index].dish[dish_index].data[0].totalprice) > parseFloat(data.totalprice)) {
          //data.totalprice = 0.00;
          //data.total = 0.00;
          //new buy one get one option
          data.totalprice = this.dishTotal;
          data.total = this.dishTotal;
          //end buy one get one option

          data.totalprice = parseFloat(data.totalprice);
          data.total = parseFloat(data.total);
          dish.data.push(data);
          this.CartDish[parent_index].dish[dish_index].data[0].free = dish;
        } else {

          dish.data.push(data);
          let backupProduct;
          backupProduct = this.CartDish[parent_index].dish[dish_index];
          this.CartDish[parent_index].dish[dish_index] = dish;
          //backupProduct.data[0].totalprice = 0.00;
          //backupProduct.data[0].total = 0.00;
          //new buy one get one option
          backupProduct.data[0].totalprice = this.dishTotal,
          backupProduct.data[0].total = this.dishTotal;
          //end buy one get one option
          backupProduct.data[0].totalprice = parseFloat(backupProduct.data[0].totalprice);
          backupProduct.data[0].total = parseFloat(backupProduct.data[0].total);

          this.CartDish[parent_index].dish[dish_index].data[0].free = backupProduct;
        }
        this.dataSharingService.isBuyGetCart.next(true);
      } else {
        dish.data.push(data);
        this.CartDish[parent_index].dish.push(dish);
        this.comservice.set('cartDetails', this.CartDish);
        this.comservice.set('tempDish',this.dish)
        this.dataSharingService.isBuyGetOption.next(true);
      }
    } else {
      let index: any;
      index = this.CheckAddtoCart(data.id, parent_index);
      if (index === -1) {
        dish.data.push(data);
        this.CartDish[parent_index].dish.push(dish);
      } else {
        this.CartDish[parent_index].dish[index].quantity = parseInt(this.CartDish[parent_index].dish[index].quantity, 10) + 1;
        if (data.sets.length === 0 && data.activeIngredients === 0) {
          let index_data: any;
          index_data = this.CheckData(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        } else if (data.sets.length === 0 && data.activeIngredients !== 0) {
          let index_data;
          index_data = this.CheckDataIngreExist(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        } else if (data.sets.length !== 0 && data.activeIngredients === 0) {
          let index_data;
          index_data = this.CheckDataSetExist(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        } else if (data.sets.length !== 0 && data.activeIngredients !== 0) {
          let index_data;
          index_data = this.CheckDataIngreSetsExist(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        }
      }
    }

    let response;
    response = {
      cartDish: this.CartDish,
      status: true
    };
    this.dialogRef.close(response);
  }
  onNoClick() {
    let response;
    response = {
      status: false
    };
    this.dialogRef.close(response);
  }
  CheckBusinessAddtoCart(d: any) {
    let b;
    b = this.CartDish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckAddtoCart(d: any, index: any) {
    let b;
    b = this.CartDish[index].dish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckData(cartdata: any, data: any) {
    if (data.sets.length === 0) {
      for (let i = 0; i < cartdata.length; i++) {
        if (cartdata[i].sets.length === 0) {
          return i;
        }
      }
      return -1;
    }
  }
  CheckDataSetExist(cartdata: any, data: any) {
    for (let i = 0; i < cartdata.length; i++) {
      if (cartdata[i].relation) {
        let arrayDiffoptions;
        arrayDiffoptions = this.arrayDiff(cartdata[i].relation.options, data.relation.options);
        let arrayDiffchoice;
        arrayDiffchoice = this.arrayDiff(cartdata[i].relation.choices, data.relation.choices);
        if (arrayDiffoptions.arr1.length === 0 && arrayDiffoptions.arr2.length === 0 && arrayDiffchoice.arr1.length === 0 && arrayDiffchoice.arr2.length === 0) {
          return i;
        }
      }
    }
    return -1;
  }
  CheckDataIngreExist(cartdata: any, data: any) {
    for (let i = 0; i < cartdata.length; i++) {
      let arrayDiffIngre;
      arrayDiffIngre = this.arrayDiff(cartdata[i].activeIngredients, data.activeIngredients);
      if (arrayDiffIngre.arr1.length === 0 && arrayDiffIngre.arr2.length === 0) {
        return i;
      }
    }
    return -1;
  }

  CheckDataIngreSetsExist(cartdata: any, data: any) {
    for (let i = 0; i < cartdata.length; i++) {
      if (cartdata[i].relation) {
        let arrayDiffoptions;
        arrayDiffoptions = this.arrayDiff(cartdata[i].relation.options, data.relation.options);
        let arrayDiffchoice;
        arrayDiffchoice = this.arrayDiff(cartdata[i].relation.choices, data.relation.choices);

        let arrayDiffIngre;
        arrayDiffIngre = this.arrayDiff(cartdata[i].activeIngredients, data.activeIngredients);

        if (arrayDiffIngre.arr1.length === 0 && arrayDiffIngre.arr2.length === 0 && arrayDiffoptions.arr1.length === 0 && arrayDiffoptions.arr2.length === 0 && arrayDiffchoice.arr1.length === 0 && arrayDiffchoice.arr2.length === 0) {
          return i;
        }
      } else {
        let arrayDiffIngre;
        arrayDiffIngre = this.arrayDiff(cartdata[i].activeIngredients, data.activeIngredients);
        if (arrayDiffIngre.arr1.length === 0 && arrayDiffIngre.arr2.length === 0) {
          return i;
        }
      }
    }
    return -1;
  }
  arrayDiff(arr1: any, arr2: any) {
    let diff;
    diff = {
      'arr1': '',
      'arr2': '',
      'concat': ''
    };

    diff.arr1 = arr1.filter((value: any) => {
      if (arr2.indexOf(value) === -1) {
        return value;
      }
    });

    diff.arr2 = arr2.filter((value: any) => {
      if (arr1.indexOf(value) === -1) {
        return value;
      }
    });

    diff.concat = diff.arr1.concat(diff.arr2);

    return diff;
  }
  editTocart() {
    if (this.dish.sets !== undefined) {
      if (this.dish.sets.length > 0) {
        for (let i = 0; i < this.dish.sets.length; i++) {
          for (let j = 0; j < this.dish.sets[i].options.length; j++) {
            if (this.dish.sets[i].options[j].show === true) {
              if (this.dish.sets[i].options[j].type === 1) {
                let min = 0;
                for (let k = 0; k < this.dish.sets[i].options[j].choice.length; k++) {
                  if (this.dish.sets[i].options[j].choice[k].checked === true) {
                    if(this.businessDetails.choose_product_option_with_qty == 1){
                      min = min + this.dish.sets[i].options[j].choice[k].qty;
                    }else{
                      min++;
                    }
                  }
                }
                if (this.dish.sets[i].options[j].required === true && min === 0) {
                  this.snackBar.open(this.dish.sets[i].options[j].name + this.language.IS_REQUIRED, this.language.TOAST_OK, {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  return false;
                } else if (min < this.dish.sets[i].options[j].min) {
                  this.snackBar.open(this.dish.sets[i].options[j].name + this.language.MINIMUM_CHOICE_IS + this.dish.sets[i].options[j].min, this.language.TOAST_OK, {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  return false;
                }
              } else if (this.dish.sets[i].options[j].required === true && this.dish.sets[i].options[j].type === 0) {
                let min = 0;
                for (let k = 0; k < this.dish.sets[i].options[j].choice.length; k++) {
                  if (this.dish.sets[i].options[j].choice[k].checked === true) {
                    min++;
                  }
                }
                if (min === 0) {
                  this.snackBar.open(this.dish.sets[i].options[j].name + this.language.IS_REQUIRED, this.language.TOAST_OK, {
                    duration: 3000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  return false;
                }
              }
            }
          }
        }
      }
    }

    this.BackupCart = this.CartDish[this.indexcart.index].dish[this.indexcart.dishindex].data[this.indexcart.dishdataindex];

    this.CartDish[this.indexcart.index].dish[this.indexcart.dishindex].quantity = this.CartDish[this.indexcart.index].dish[this.indexcart.dishindex].quantity - this.CartDish[this.indexcart.index].dish[this.indexcart.dishindex].data[this.indexcart.dishdataindex].quantity;
    this.CartDish[this.indexcart.index].dish[this.indexcart.dishindex].data.splice(this.indexcart.dishdataindex, 1);
    let dishTal;
    dishTal = parseFloat(this.dishTotal) * parseInt(this.BackupCart.quantity, 10);
    let dishTotal = dishTal.toString();
    dishTotal = parseFloat(dishTotal).toFixed(2);

    let activeIngredients;
    activeIngredients = new Array();
    for (const ingre of this.dish.ingredientsarray) {
      if (ingre.status === false) {
        activeIngredients.push(ingre.name);
      }
    }

    let data: any;
    data = {
      'id': this.dish.id,
      'name': this.dish.name,
      'price': this.dish.price,
      'delprice': this.dish.delprice,
      'pickprice': this.dish.pickprice,
      'offerprice': this.dish.offerprice,
      'pofferprice': this.dish.pofferprice,
      'notes': this.dish.notes,
      'totalprice': this.dishTotal,
      'total': dishTotal,
      'is_img': this.dish.is_img,
      'img': this.dish.img,
      'spicy': this.dish.spicy,
      'spicyquantity': this.dish.spicyquantity,
      'veg': this.dish.veg,
      'nonveg': this.dish.nonveg,
      'ingredients': this.dish.ingredients,
      'ingredientsarray': this.dish.ingredientsarray,
      'activeIngredients': activeIngredients,
      'activeIngredientsText': activeIngredients.join(', '),
      'quantity': this.BackupCart.quantity,
      'sets': new Array(),
      'relation': {
        'options': new Array(),
        'choices': new Array()
      },
      'free': {}
    };
    let optionsarray;
    optionsarray = new Array();
    let choicesarray;
    choicesarray = new Array();
    if (this.dish.sets !== undefined) {
      for (let i = 0; i < this.dish.sets.length; i++) {
        for (let j = 0; j < this.dish.sets[i].options.length; j++) {
          let options;
          options = {
            'id': this.dish.sets[i].options[j].id,
            'name': this.dish.sets[i].options[j].name,
            'choice': new Array(),
            'choiceText': ''
          };
          let choiceTextArray;
          choiceTextArray = new Array();
          for (let k = 0; k < this.dish.sets[i].options[j].choice.length; k++) {
            if (this.dish.sets[i].options[j].choice[k].checked === true) {
              let choice;
              if(this.businessDetails.choose_product_option_with_qty == 1){
                choice = {
                  'id': this.dish.sets[i].options[j].choice[k].id,
                  'name': this.dish.sets[i].options[j].choice[k].name,
                  'price': this.dish.sets[i].options[j].choice[k].price * this.dish.sets[i].options[j].choice[k].qty,
                  'qty': this.dish.sets[i].options[j].choice[k].qty
                };
              }else{
                choice = {
                  'id': this.dish.sets[i].options[j].choice[k].id,
                  'name': this.dish.sets[i].options[j].choice[k].name,
                  'price': this.dish.sets[i].options[j].choice[k].price,
                  'qty': this.dish.sets[i].options[j].choice[k].qty
                };
              }

              if(this.businessDetails.choose_product_option_with_qty == 1){
                choiceTextArray.push(this.dish.sets[i].options[j].choice[k].name + "  x  " + this.dish.sets[i].options[j].choice[k].qty);

              }else{
                choiceTextArray.push(this.dish.sets[i].options[j].choice[k].name);

              }
              choicesarray.push(choice.id);
              options.choice.push(choice);
            }
          }
          options.choiceText = choiceTextArray.join(', ');
          if (options.choice.length > 0) {
            optionsarray.push(options.id);
            data.sets.push(options);
          }
        }
      }
    }

    if (data.sets.length > 0) {
      data.relation = {
        'options': optionsarray,
        'choices': choicesarray
      };
    }




    let parent_index: any;
    parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    let index: any;
    index = this.CheckAddtoCart(data.id, parent_index);

    if (data.sets.length === 0 && data.activeIngredients === 0) {
      let index_data: any;
      index_data = this.CheckData(this.CartDish[parent_index].dish[index].data, data);
      if (index_data === -1) {
        this.CartDish[parent_index].dish[index].data.push(data);
        this.CartDish[parent_index].dish[index].quantity = data.quantity;
      } else {
        this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
        this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
      }
    } else if (data.sets.length === 0 && data.activeIngredients !== 0) {
      let index_data;
      index_data = this.CheckDataIngreExist(this.CartDish[parent_index].dish[index].data, data);
      if (index_data === -1) {
        this.CartDish[parent_index].dish[index].data.push(data);
        this.CartDish[parent_index].dish[index].quantity = data.quantity;
      } else {
        this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
        this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
      }
    } else if (data.sets.length !== 0 && data.activeIngredients === 0) {
      let index_data;
      index_data = this.CheckDataSetExist(this.CartDish[parent_index].dish[index].data, data);
      if (index_data === -1) {
        this.CartDish[parent_index].dish[index].data.push(data);
        this.CartDish[parent_index].dish[index].quantity = data.quantity;
      } else {
        this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
        this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
      }
    } else if (data.sets.length !== 0 && data.activeIngredients !== 0) {
      let index_data;
      index_data = this.CheckDataIngreSetsExist(this.CartDish[parent_index].dish[index].data, data);
      if (index_data === -1) {
        this.CartDish[parent_index].dish[index].data.push(data);
        this.CartDish[parent_index].dish[index].quantity = data.quantity;
      } else {
        this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
        this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
        this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
      }
    }

    this.indexcart.EDITPRODUCTCART = 0;
    let response;
    response = {
      cartDish: this.CartDish,
      status: true
    };

    this.dialogRef.close(response);
  }

  
  choiceQtyDecrement(setIndex: any, optionIndex: any, choiceIndex: any, choice_id: any){

    if(this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].qty > 1){
      this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].qty = this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].qty - 1;

      //this.dishTotal = this.dish.price;
      if (this.businessDetails.buyone_getone_status) {
     
        if (this.dataType == 2) {
          this.dishTotal = 0.00;
        }else{
          this.dishTotal = this.dish.price;
        }
      }else{
        this.dishTotal = this.dish.price;
      }

      if(this.businessDetails.choose_product_option_with_qty == 1){
        for (let k = 0; k < this.dish.sets.length; k++) {
          for (let i = 0; i < this.dish.sets[k].options.length; i++) {
            if (this.dish.sets[k].options[i].show === true) {
              for (let j = 0; j < this.dish.sets[k].options[i].choice.length; j++) {
                if (this.dish.sets[k].options[i].choice[j].checked === true) {
                  this.dishTotal = parseFloat(this.dishTotal) + (parseFloat(this.dish.sets[k].options[i].choice[j].price) * parseInt(this.dish.sets[k].options[i].choice[j].qty));
                  this.dishTotal = parseFloat(this.dishTotal).toFixed(2);
                }
              }
            }
          }
        }
      }else{
      
          for (let k = 0; k < this.dish.sets.length; k++) {
            for (let i = 0; i < this.dish.sets[k].options.length; i++) {
              if (this.dish.sets[k].options[i].show === true) {
                for (let j = 0; j < this.dish.sets[k].options[i].choice.length; j++) {
                  if (this.dish.sets[k].options[i].choice[j].checked === true) {
                    this.dishTotal = parseFloat(this.dishTotal) + parseFloat(this.dish.sets[k].options[i].choice[j].price);
                    this.dishTotal = parseFloat(this.dishTotal).toFixed(2);
                  }
                }
              }
            }
          }
      }

    }

  }

  choiceQtyIncrement(setIndex: any, optionIndex: any, choiceIndex: any, choice_id: any){
    let trues = 0;
    
    for (let i = 0; i < this.dish.sets[setIndex].options[optionIndex].choice.length; i++) {
      if (this.dish.sets[setIndex].options[optionIndex].choice[i].checked === true) {
        trues = trues + this.dish.sets[setIndex].options[optionIndex].choice[i].qty;
      }
    }
    console.log(trues);
    if (trues >= this.dish.sets[setIndex].options[optionIndex].max) {
      this.snackBar.open(this.language.MAX_NUMBER_SELECTION + this.dish.sets[setIndex].options[optionIndex].max, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }else{
      this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].qty = this.dish.sets[setIndex].options[optionIndex].choice[choiceIndex].qty + 1;

    }



    //this.dishTotal = this.dish.price;

    if (this.businessDetails.buyone_getone_status) {
     
      if (this.dataType == 2) {
        this.dishTotal = 0.00;
      }else{
        this.dishTotal = this.dish.price;
      }
    }else{
      this.dishTotal = this.dish.price;
    }


    if(this.businessDetails.choose_product_option_with_qty == 1){
      for (let k = 0; k < this.dish.sets.length; k++) {
        for (let i = 0; i < this.dish.sets[k].options.length; i++) {
          if (this.dish.sets[k].options[i].show === true) {
            for (let j = 0; j < this.dish.sets[k].options[i].choice.length; j++) {
              if (this.dish.sets[k].options[i].choice[j].checked === true) {
                this.dishTotal = parseFloat(this.dishTotal) + (parseFloat(this.dish.sets[k].options[i].choice[j].price) * parseInt(this.dish.sets[k].options[i].choice[j].qty));
                this.dishTotal = parseFloat(this.dishTotal).toFixed(2);
              }
            }
          }
        }
      }
    }else{
      for (let k = 0; k < this.dish.sets.length; k++) {
        for (let i = 0; i < this.dish.sets[k].options.length; i++) {
          if (this.dish.sets[k].options[i].show === true) {
            for (let j = 0; j < this.dish.sets[k].options[i].choice.length; j++) {
              if (this.dish.sets[k].options[i].choice[j].checked === true) {
                this.dishTotal = parseFloat(this.dishTotal) + parseFloat(this.dish.sets[k].options[i].choice[j].price);
                this.dishTotal = parseFloat(this.dishTotal).toFixed(2);
              }
            }
          }
        }
      }
    }
    
  }


  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
