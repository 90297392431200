<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="ball-scale-ripple-multiple"></ngx-spinner>
<div class="maincontent">
  <header id="myHeader"
    [ngClass]="{'inner-page': route != 'Home', 'scroll-out': stickyheight > 5 && route == 'Home', 'single-business': headerType == true }"
    *ngIf="langFlag && route != '/404' && route !='/payment'">
    <div class="logo" *ngIf="route != 'Home' && appType == true">
      <a href="/"><img [lazyLoad]="logoImage" /></a>
    </div>
    <ul>
      <li *ngIf="isUserLoggedIn"><a href="/profile" class="mat-log">{{userdetails.name}} {{userdetails.last_name}}</a>
      </li>
      <li *ngIf="!isUserLoggedIn"><a href="javascript:void(0)" (click)="goToLogin()"
          class="mat-log">{{language.LOGIN}}</a></li>
      <li *ngIf="cartQuantity > 0"><a href="javascript:void(0)" (click)="goToBusiness()" class="mat-log crt-log"><i
            class="fa fa-shopping-cart" aria-hidden="true"></i> <span>{{cartQuantity}}</span></a></li>
    </ul>
  </header>
  <div class="router-outlet">
    <router-outlet *ngIf="langFlag"></router-outlet>
  </div>
  <footer *ngIf="appType && langFlag == true && route !='/404' && route !='/login' && route !='/payment'">
    <div class=" container ">
      <div class="row ">
        <div class="col-md-6 col-sm-6 col-xs-12 ">
          <div class="ftr-logo ">
            <a href="/">
              <img [lazyLoad]="footerLogoImage" />
            </a>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12 " *ngIf="langlist.length > 1">
          <div class="language ">
            <div class="form-group ">
              <div class="input-group ">
                <div class="input-group-addon "><svg version="1.1 " id="Capa_1 " xmlns="http://www.w3.org/2000/svg "
                    xmlns:xlink="http://www.w3.org/1999/xlink " x="0px " y="0px " viewBox="0 0 15 15 " style="enable-background:new 0 0 15 15;
      " xml:space="preserve ">
                    <g>
                      <path
                        d="M14.982,7C14.736,3.256,11.744,0.263,8,0.017V0H7.5H7v0.017C3.256,0.263,0.263,3.256,0.017,7H0v0.5 V8h0.017C0.263,11.744,3.256,14.736,7,14.982V15h0.5H8v-0.018c3.744-0.246,6.736-3.238,6.982-6.982H15V7.5V7H14.982z M4.695,1.635
      C4.212,2.277,3.811,3.082,3.519,4H2.021C2.673,2.983,3.599,2.16,4.695,1.635z M1.498,5h1.758C3.122,5.632,3.037,6.303,3.01,7H1.019 C1.072,6.296,1.238,5.623,1.498,5z M1.019,8H3.01c0.027,0.697,0.112,1.368,0.246,2H1.498C1.238,9.377,1.072,8.704,1.019,8z M2.021,11h1.497c0.292,0.918,0.693,1.723,1.177,2.365C3.599,12.84,2.673,12.018,2.021,11z
      M7,13.936 C5.972,13.661,5.087,12.557,4.55,11H7V13.936z M7,10H4.269C4.128,9.377,4.039,8.704,4.01,8H7V10z M7,7H4.01 c0.029-0.704,0.118-1.377,0.259-2H7V7z M7,4H4.55C5.087,2.443,5.972,1.339,7,1.065V4z M12.979,4h-1.496 c-0.293-0.918-0.693-1.723-1.178-2.365C11.4,2.16,12.327,2.983,12.979,4z
      M8,1.065C9.027,1.339,9.913,2.443,10.45,4H8V1.065z M8,5 h2.73c0.142,0.623,0.229,1.296,0.26,2H8V5z M8,8h2.99c-0.029,0.704-0.118,1.377-0.26,2H8V8z M8,13.936V11h2.45 C9.913,12.557,9.027,13.661,8,13.936z M10.305,13.365c0.483-0.643,0.885-1.447,1.178-2.365h1.496
      C12.327,12.018,11.4,12.84,10.305,13.365z M13.502,10h-1.758c0.134-0.632,0.219-1.303,0.246-2h1.99 C13.928,8.704,13.762,9.377,13.502,10z M11.99,7c-0.027-0.697-0.112-1.368-0.246-2h1.758c0.26,0.623,0.426,1.296,0.479,2H11.99z " />
                    </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                    <g> </g>
                  </svg> </div>
                <select class="form-control " (change)="onLangChange($event) " >
                  <option *ngFor="let lang of langlist " [value]="lang.id " [selected]="lang.id == langId">{{lang.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ftr-menu " style="border-top:0 !important;     margin-top: 0 !important;">

      </div>

      <div class="ftr-imp ">
        <div class="row ">
          <div class="col-md-6 col-sm-6 col-xs-12 ">
            <div class="ftr-imp-link ">
              <ul>
                <li><a href="https://menuhuts.com/product/aboutus" target="_blank">{{language.ABOUT_US}}</a></li>
                <li><a href="https://menuhuts.com/product/product" target="_blank">{{language.OUR_PRODUCT}}</a></li>
                <li><a href="https://menuhuts.com/product/pricing" target="_blank">{{language.PRICING}}</a></li>
                <li><a href="https://menuhuts.com/product/contactus" target="_blank">{{language.CONTACT_US}}</a></li>
                <li><a href="https://menuhuts.com/product/privacy" target="_blank">{{language.PRIVACY}}</a></li>
                <li><a href="https://intercom.help/menuhuts/en/" target="_blank">{{language.HELP}}</a></li>

              </ul>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 ">
            <div class="acpt ">
              <span>{{language.WE_ACCEPT}}</span>
              <span>
                <img [lazyLoad]="acptImage" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12 col-sm-12 col-xs-12 ">
          <div class="ftr-cpy ">
            <p>{{language.COPYRIGHT}}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <footer *ngIf="!appType" style="padding: 7px 0 10px; text-align: center; ">
    <div class=" container ">
      <div class="row ">
        <div class="col-md-12 col-sm-12 col-xs-12 ">
          <div class="ftr-cpy ">
            <p style="color: #fff; opacity: 1; font-size: 12px;">Powered By <a
                style="font-size: 12px; color: #FFF; text-decoration: underline !important;"
                href="https://menuhuts.com/" target="_blank">MenuHuts</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
<div class="web-orientation">
  <img [lazyLoad]="rotateImage" />
  <h1 *ngIf="language">{{language.ROTATE_YOUR_DEVICE}}</h1>
  <p *ngIf="language">{{language.ROTATE_YOUR_DEVICE_DESCR}}</p>
</div>
