import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { ElementRef, PLATFORM_ID } from '@angular/core';
import { Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from './../data-share.service';
import { Globals } from '../globals';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  options = { autoHide: true, scrollbarMinSize: 100 };
  public globals: Globals;
  public route: any;
  public hide = true;
  public signuphide = true;
  public prev: any;
  // private user: SocialUser;
  private loggedIn: any;
  public loginform: any = {
    email: '',
    password: ''
  };
  public signupuser: any = {name: '', last_name: '', email: '', pass: '', terms: false };
  public forgot: any = {email: ''};
  public language: any;
  public loginPopup = true;
  public signinPopup = false;
  public forgotPopup = false;

  @ViewChild('lemail') lemail!: ElementRef;
  @ViewChild('lpassword') lpassword!: ElementRef;
  @ViewChild('sname') public sname!: ElementRef;
  @ViewChild('slname') public slname!: ElementRef;
  @ViewChild('semail') public semail!: ElementRef;
  @ViewChild('spass') public spass!: ElementRef;
  @ViewChild('femail') public femail!: ElementRef;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef < LoginComponent > ,
    private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: SocialAuthService,
    public router: Router,
    private dataSharingService: DataSharingService,
    private comservice: CommonService,
    private apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    let uid: any;
    uid = this.comservice.getFromLocal('userId');
    let cartDish: any;
    cartDish = this.comservice.getService('cartDetails');
    let businessDetails: any;
    businessDetails = this.comservice.getService('businessDetails');
    if (cartDish !== undefined) {
      if (cartDish.length > 0 && uid > 0) {
        this.router.navigate(['/' + businessDetails.slug]);
      }
    } else {
      if (uid > 0) {
        this.router.navigate(['/']);
      }
    }

    this.prev = this.comservice.getService('prevroute');
    $('.button').click(() => {
      let buttonId: any;
      buttonId = $(this).attr('id');
      $('#modal-container').removeAttr('class').addClass(buttonId);
      $('body').addClass('modal-active');
    });

    $('.close-modal').click(function () {
      $('#modal-container').addClass('out');
      $('body').removeClass('modal-active');
    });
  }
  initiateSocial(val: any) {
    this.authService.authState.subscribe((user) => {
      if (user !== null) {
        let name;
        name = user.name.split(' ');
        let socialuser;
        socialuser = {
          name: name[0],
          lastname: name[1],
          email: user.email,
          image: user.photoUrl,
          type: 1
        };
        this.apiservice.getSocialLogin(socialuser).subscribe((res: any) => {
          if (res.status) {
            this.comservice.setService('user-details', res.result);
            this.comservice.saveInLocal('user-details', res.result);
            this.comservice.saveInLocal('userId', res.result.id);
            this.apiservice.getLoginCheck(res.result.id).subscribe((result: any) => {
              this.dataSharingService.isUserLoggedIn.next(true);
              let prevroute: any;
              prevroute = this.comservice.getService('prevroute');
              if (prevroute === 'checkout') {
                this.router.navigate(['/' + prevroute]);
              } else {
                this.onNoClick();
              }
            });
          } else {
            this.snackBar.open(res.message, this.language.TOAST_OK, {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          }
        });
      }
    });
    if (val === 1) {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }
    if (val === 2) {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }
  }
  signInWithGoogle(): void {
    this.initiateSocial(1);
  }

  signInWithFB(): void {
    this.initiateSocial(2);
  }

  onLoginSubmit() {
    if (this.loginform.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.lemail.nativeElement.focus();
      }
    } else if (this.loginform.password === '') {
      this.snackBar.open(this.language.ENTER_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.lpassword.nativeElement.focus();
      }
    }
    this.apiservice.getLogin(this.loginform.email, this.loginform.password).subscribe((res: any) => {
      if (res.status) {
        this.comservice.setService('user-details', res.result);
        this.comservice.saveInLocal('user-details', res.result);
        this.comservice.saveInLocal('userId', res.result.id);
        this.apiservice.getLoginCheck(res.result.id).subscribe((result: any) => {

        });
        this.dataSharingService.isUserLoggedIn.next(true);
        let prevroute: any;
        prevroute = this.comservice.getService('prevroute');
        if (prevroute === 'checkout') {
          this.router.navigate(['/' + prevroute]);
        }
        this.onNoClick();
      } else {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }
  changeToLogin() {
    this.loginPopup = true;
    this.signinPopup = false;
    this.forgotPopup = false;
  }
  changeTosignUp() {
    this.loginPopup = false;
    this.signinPopup = true;
    this.forgotPopup = false;
  }
  onSignUpSubmit() {
    if (this.signupuser.name === '') {
      this.snackBar.open(this.language.ENTER_FIRST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.sname.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.last_name === '') {
      this.snackBar.open(this.language.ENTER_YOUR_LAST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.slname.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.semail.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.signupuser.email.indexOf('@');
    let dotpos;
    dotpos = this.signupuser.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.signupuser.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.semail.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.pass === '') {
      this.snackBar.open(this.language.ENTER_YOUR_PASSWORD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.spass.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.pass.length < 6) {
      this.snackBar.open(this.language.ENTER_YOUR_PASSWORD_LENGTH, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.spass.nativeElement.focus();
      }
      return false;
    }
    if (this.signupuser.terms === false) {
      this.snackBar.open(this.language.CHECK_PRIVACY_TERMS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    this.apiservice.setSignUp(this.signupuser).subscribe((res: any) => {
      if (res.status) {
        this.comservice.setService('user-details', res.result);
        this.comservice.saveInLocal('user-details', res.result);
        this.comservice.saveInLocal('userId', res.result.id);
        this.apiservice.getLoginCheck(res.result.id).subscribe((rest: any) => {
          this.dataSharingService.isUserLoggedIn.next(true);
          let prevroute: any;
          prevroute = this.comservice.getService('prevroute');
          if (prevroute === 'checkout') {
            this.router.navigate(['/' + prevroute]);
          }
          this.onNoClick();
        });

        this.dataSharingService.isUserLoggedIn.next(true);
        this.onNoClick();

      } else {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }
  changeToForgotPass() {
    this.loginPopup = false;
    this.signinPopup = false;
    this.forgotPopup = true;
  }
  onForgotSubmit() {
    if (this.forgot.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.femail.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.forgot.email.indexOf('@');
    let dotpos;
    dotpos = this.forgot.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.forgot.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.femail.nativeElement.focus();
      }
      return false;
    }
    this.apiservice.getForgot(this.forgot).subscribe((res: any) => {
      if (res.status) {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.onNoClick();
        this.forgot = { email: '' };
      } else {
        this.snackBar.open(res.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    });
  }
  guestCheckOut() {
    this.onNoClick();
    this.router.navigate(['/checkout']);
    this.comservice.scrollTop();
  }
}
