import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Globals } from '../globals';

export interface DialogData {
  notification: any;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent {
  public globals: Globals;
  public notificationDetails: any;
  public language: any;

  constructor(
    public dialogRef: MatDialogRef < NotificationComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
    this.notificationDetails = data.notification;
  }

  onNoClick() {
    let response;
    response = {
      status: false
    };
    this.dialogRef.close(response);
  }
}
