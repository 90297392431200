import { WINDOW } from '@ng-toolkit/universal';
import { Component, ComponentFactory, ComponentFactoryResolver, OnDestroy, ChangeDetectorRef, ViewRef  } from '@angular/core';
import { HostListener, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { InfoComponent } from './../info/info.component';
import { PhotoComponent } from './../photo/photo.component';
import { ReviewsComponent } from './../reviews/reviews.component';
import { OffersComponent } from './../offers/offers.component';
import { PreorderComponent } from './../preorder/preorder.component';
import { WhereruComponent } from '../whereru/whereru.component';
import { DishComponent } from '../dish/dish.component';
import { BookingComponent } from '../booking/booking.component';
import { SuggestionComponent } from '../suggestion/suggestion.component';
import { DataSharingService } from './../data-share.service';
import { RequiredminimumComponent } from '../requiredminimum/requiredminimum.component';
import { AlertComponent } from '../alert/alert.component';
import { LoginComponent } from '../login/login.component';
import { Globals } from '../globals';
import { Title, Meta } from '@angular/platform-browser';
import { BuygetComponent } from '../buyget/buyget.component';

declare var $: any;

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})

export class BusinessComponent implements OnInit, OnDestroy {
  public globals: Globals;
  options = { autoHide: true, scrollbarMinSize: 100 };
  route: string;
  public businessId: any;
  public businessDetails: any;
  public categoryDetails: any;
  public MenuDetails: any;
  public userdetails: any;
  public FavBusiness: boolean;
  public cartDetails: any;
  public orderType: any;
  public CartDish: any;
  public CartSubTotal: any;
  public PendingMinimumFee: any;
  public PendingFreeDelivery = false;
  public PendingFreeDeliveryValue: any;
  public myClonedcategoryDetails;
  public myClonedcategory;
  public from_order: boolean;
  public indexcart: any;
  public searchText: any;
  public tabscroll: any;
  public preorderDetails: any = {
    preorder: false,
    preorderDate: '',
    preorderTime: '',
    preorderMenu: 0
  };
  public menuDisplay = true;
  public productDisplay = false;
  public language: any;
  public suggestProduct: any = [];
  public suggestProductArray: any = [];
  public catLength: Number = 0;
  public catLimit: Number = 0;
  public scroll = 0;
  public appType;
  public cartQuantity = 0;
  public stickyheight: any;

  public selectable = false;
  public removable = true;
  public dish: any;
  public dishTotal: any;
  public from_order_pos: any;

  public carImage = './assets/images/car.png';

  public buyOneGetOneProduct: any = [];

  @ViewChild('businessSubContainer1', { read: ViewContainerRef }) businessSubContainer1: ViewContainerRef;
  @ViewChild('businessSubContainer2', { read: ViewContainerRef }) businessSubContainer2: ViewContainerRef;
  @ViewChild('businessSubContainer3', { read: ViewContainerRef }) businessSubContainer3: ViewContainerRef;
  @ViewChild('businessSubContainer4', { read: ViewContainerRef }) businessSubContainer4: ViewContainerRef;
  @ViewChild('businessSubContainer5', { read: ViewContainerRef }) businessSubContainer5: ViewContainerRef;

  constructor(
    @Inject(WINDOW) private window: Window,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private compFactoryResolver: ComponentFactoryResolver,
    public location: Location,
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    globals: Globals,
    private titleService: Title,
    private metaService: Meta,
    private cdRef: ChangeDetectorRef
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.appType = this.globals.APP_TYPE;
    let bDetails;
    bDetails = this.comservice.getService('businessDetails');

    this.titleService.setTitle(bDetails.name);
    this.metaService.updateTag({name: 'keywords', content: bDetails.keywords});
    this.metaService.updateTag({name: 'description', content: bDetails.description});

    this.metaService.updateTag({property: 'og:title', content: bDetails.name});
    this.metaService.updateTag({property: 'og:image', content: bDetails.logo});
    this.metaService.updateTag({property: 'og:url', content: this.globals.SITE_URL + bDetails.slug});

    this.dataSharingService.isCartAdded.subscribe(value => {
      if (value === true) {
        this.CartDish = this.comservice.get('cartDetails');
        this.cartQuantity = 0;
        let cart;
        for (cart of this.CartDish) {
          let dish;
          for (dish of cart.dish) {
            this.cartQuantity = this.cartQuantity + dish.quantity;
          }
        }
        this.calCartTotal();
        this.dataSharingService.isCartAdded.next(false);
      }
    });

    this.dataSharingService.isBuyGetOption.subscribe(value => {
      if (value === true) {
        this.CartDish = this.comservice.get('cartDetails');
        let tempDish = this.comservice.get('tempDish');
        console.log(this.buyOneGetOneProduct, this.businessDetails, this.CartDish, tempDish.freeitem )
        const buygetDialogRef = this.dialog.open(BuygetComponent, {
          width: '700px',
          data: {
            productList: this.buyOneGetOneProduct,
            businessDetails: this.businessDetails,
            CartDish: this.CartDish,
            freeItem: tempDish.freeitem
          },
          disableClose: true
        });

        buygetDialogRef.afterClosed().subscribe(result => {
          this.calCartTotal();
        });
        this.dataSharingService.isBuyGetOption.next(false);
      }
    });
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = this.window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.stickyheight = number;
  }
  goToBusiness() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/' + this.CartDish[0].details.slug]);
    });
  }
  ngOnInit() {
    if (this.globals.APP_TYPE === false) {
      this.dataSharingService.isSingleBusiness.next(true);
    }
    this.from_order = false;
    this.comservice.scrollTop();
    this.indexcart = new Object();
    this.businessId = this.comservice.getService('businessID');
    this.businessDetails = this.comservice.getService('businessDetails');

    this.businessDetails.freedeliveryvalue = parseFloat(this.businessDetails.freedeliveryvalue).toFixed(2);
    this.businessDetails.time1 = true;
    this.businessDetails.time2 = true;
    if (this.businessDetails.today_schedule.opens.hour === '00' && this.businessDetails.today_schedule.opens.minute === '00' && this.businessDetails.today_schedule.closes.hour === '00' && this.businessDetails.today_schedule.closes.minute === '00') {
      this.businessDetails.time1 = false;
    }
    if (this.businessDetails.today_schedule.opens2.hour === '00' && this.businessDetails.today_schedule.opens2.minute === '00' && this.businessDetails.today_schedule.closes2.hour === '00' && this.businessDetails.today_schedule.closes2.minute === '00') {
      this.businessDetails.time2 = false;
    }

    let preorderDetails;
    preorderDetails = this.comservice.get('preorderDetails');
    if (preorderDetails === undefined || preorderDetails === '') {
      this.comservice.set('preorderDetails', this.preorderDetails);
    } else {
      this.preorderDetails = preorderDetails;
    }

    if (this.preorderDetails.preorder) {
      let dString = new Date(this.preorderDetails.preorderDateString);
      let dayNumber;
      if (dString.getDay() == 0) {
        dayNumber = 7;
      } else {
        dayNumber = dString.getDay();
      }

      if (this.businessDetails?.buyone_getone_days !== null) {
        for(let bday of this.businessDetails.buyone_getone_days) {
          if (bday.id == dayNumber) {
            if (bday.status == true) {
              this.businessDetails.buyone_getone_status = true;
            } else {
              this.businessDetails.buyone_getone_status = false;
            }
          }
        }
      } else {
        this.businessDetails.buyone_getone_status = false;
      }
    }



    let CartDish;
    CartDish = this.comservice.get('cartDetails');
    console.log(CartDish);
    this.cartQuantity = 0;
    if (CartDish !== undefined && Array.isArray(CartDish)) {
      this.CartDish = CartDish;
      let cart;
      for (cart of this.CartDish) {
        let dish;
        for (dish of cart.dish) {
          this.cartQuantity = this.cartQuantity + dish.quantity;
        }
      }
    } else {
      this.CartDish = new Array();
    }
    if (this.businessDetails.addtofav === true) {
      this.FavBusiness = true;
    } else {
      this.FavBusiness = false;
    }
    let orderType;
    orderType = this.comservice.get('orderType');
    if (orderType === undefined || orderType === '') {
      this.orderType = '0';
    } else {
      this.orderType = orderType;
    }
    this.comservice.set('orderType', this.orderType);

    if (this.orderType === '1' && this.businessDetails.is_delivery !== '1') {
      this.cartRemove();
    }

    if (this.orderType === '2' && this.businessDetails.is_pickup !== '1') {
      this.cartRemove();
    }

    let MenuDetails;
    MenuDetails = this.comservice.getService('MenuDetails');

    if (Object.keys(MenuDetails).length === 0) {
      this.menuDisplay = false;
      this.productDisplay = false;
      this.openBusiness();
      this.snackBar.open(this.language.NOT_ACTIVE_MENU, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    } else {
      this.categoryDetails = this.comservice.getService('categoryDetails');
      this.myClonedcategoryDetails = JSON.stringify(this.categoryDetails);
      let myClonedcategory;
      myClonedcategory = JSON.stringify(this.categoryDetails);
      this.myClonedcategory = JSON.parse(myClonedcategory);

      this.catLength = this.categoryDetails.length;
      this.MenuDetails = MenuDetails;


      let myClonedcategoryArray;
      myClonedcategoryArray = JSON.parse(myClonedcategory);
      myClonedcategoryArray.forEach((element, index) => {
        this.apiservice.getProductByCategory(JSON.stringify(element.dishId), this.orderType).subscribe((responseProduct: any) => {
          this.categoryDetails[index].dish = responseProduct;
          this.myClonedcategory[index].dish = responseProduct;
          responseProduct.forEach((productElement, productIndex) => {
            this.MenuDetails.forEach((menuElement) => {
              if (menuElement.dishes.indexOf(productElement.id) > -1) {
                if (productElement.dishstatus !== 1) {
                  productElement.dishstatus = menuElement.menustatus;
                }
                if (productElement.pickup === undefined) {
                  productElement.pickup = menuElement.pickup;
                }

                if (productElement.delivery === undefined) {
                  productElement.delivery = menuElement.delivery;
                }

                if (productElement.pickup === '0') {
                  productElement.pickup = menuElement.pickup;
                }

                if (productElement.delivery === '0') {
                  productElement.delivery = menuElement.delivery;
                }




              }
            });
            element.subCat.forEach((subElement, subIndex) => {
              if (this.categoryDetails[index].subCat[subIndex].dishId.indexOf(productElement.id) > -1) {
                this.categoryDetails[index].subCat[subIndex].dish.push(productElement);
                this.myClonedcategory[index].subCat[subIndex].dish.push(productElement);
              }
            });
            if (this.businessDetails.buyone_getone_status) {
              this.buyOneGetOneProduct.push(productElement);
            }
          });
          this.categoryDetails[index].activeDish = true;
          this.myClonedcategory[index].activeDish = true;
          if (this.productDisplay === false) {
            this.productDisplay = true;
            this.openBusiness();
          }
        });
      });

      let deliveryDetails;
      deliveryDetails = this.comservice.get('deliveryDetails');
      if (deliveryDetails !== '') {
        if (deliveryDetails !== undefined) {
          this.businessDetails.minimumfee = parseFloat(deliveryDetails.minimum).toFixed(2);
          this.businessDetails.delivery = parseFloat(deliveryDetails.delivery).toFixed(2);
        }
      }
      this.calCartTotal();
    }
  }
  cartRemove() {
    this.CartDish = new Array();
    this.comservice.set('cartDetails', this.CartDish);
    this.orderType = '0';
    this.comservice.set('orderType', this.orderType);
    this.preorderDetails = {
      preorder: false,
      preorderDate: '',
      preorderTime: '',
      preorderMenu: 0,
      preorderMenuSchedule: '',
      preorderDateString: ''
    };
    this.comservice.set('preorderDetails', this.preorderDetails);
    this.dataSharingService.isCartAdded.next(true);
  }
  ngOnDestroy() {
    if (this.globals.APP_TYPE === false) {
      this.dataSharingService.isSingleBusiness.next(false);
    }
  }
  openBusiness() {
    let compFactory: ComponentFactory < any > ;
    compFactory = this.compFactoryResolver.resolveComponentFactory(InfoComponent);
    this.businessSubContainer1.createComponent(compFactory);

    compFactory = this.compFactoryResolver.resolveComponentFactory(BookingComponent);
    this.businessSubContainer5.createComponent(compFactory);

    let that;
    that = this;
    $(function () {
      let b;
      b = 'fadeInLeft';
      let c;
      let a;
      d($('#myTab1 a'), $('#tab-content1'), false);

      function d(e, f, g) {
        e.click(function (i) {
          i.preventDefault();
          $(this).tab('show');

          let hrefa;
          hrefa = this.href.split('#');
          if (hrefa[1] === 'photos') {
            that.photosLoad(compFactory);
          } else if (hrefa[1] === 'reviews') {
            that.reviewsLoad(compFactory);
          } else if (hrefa[1] === 'offers') {
            that.offersLoad(compFactory);
          }
          let h;
          h = $(this).data('easein');
          if (c) {
            c.removeClass(a);
          }
          if (h) {
            f.find('div.active').addClass('animated ' + h);
            a = h;
          } else {
            if (g) {
              f.find('div.active').addClass('animated ' + g);
              a = g;
            } else {
              f.find('div.active').addClass('animated ' + b);
              a = b;
            }
          }
          c = f.find('div.active');

        });
      }
    });

    if (this.CartDish.length > 0) {
      if (this.CartDish[0].id !== this.businessDetails.id) {
        const alertdialogRef = this.dialog.open(AlertComponent, {
          width: '500px',
          data: {
            title: this.language.CONFIRMATION,
            note: this.language.DONT_ACCEPT_MULTI_ORDER,
            ok: this.language.BUTTON_OK,
            cancel: this.language.CANCEL
          },
          disableClose: true
        });

        alertdialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.CartDish = new Array();
            this.comservice.set('cartDetails', this.CartDish);
            this.orderType = '0';
            this.comservice.set('orderType', this.orderType);
            this.preorderDetails = {
              preorder: false,
              preorderDate: '',
              preorderTime: '',
              preorderMenu: 0,
              preorderMenuSchedule: '',
              preorderDateString: ''
            };
            this.comservice.set('preorderDetails', this.preorderDetails);
            this.dataSharingService.isCartAdded.next(true);
          } else {
            this.goToBusiness();
          }
        });
      } else {
        this.calCartTotal();
      }
    }
  }
  photosLoad(compFactory) {
    this.businessSubContainer2.clear();
    compFactory = this.compFactoryResolver.resolveComponentFactory(PhotoComponent);
    this.businessSubContainer2.createComponent(compFactory);
  }
  reviewsLoad(compFactory) {
    this.businessSubContainer3.clear();
    compFactory = this.compFactoryResolver.resolveComponentFactory(ReviewsComponent);
    this.businessSubContainer3.createComponent(compFactory);
  }
  offersLoad(compFactory) {
    this.businessSubContainer4.clear();
    compFactory = this.compFactoryResolver.resolveComponentFactory(OffersComponent);
    this.businessSubContainer4.createComponent(compFactory);
  }
  goToPreorder(): void {
    let business;
    business = {
      id: this.businessDetails.id,
      name: this.businessDetails.name,
      logo: this.businessDetails.logo,
      code: this.businessDetails.timezone_code
    };
    let preorder;
    preorder = {
      preorder: false,
      preorderDate: '',
      preorderTime: '',
      preorderMenu: 0
    };
    const dialogRef = this.dialog.open(PreorderComponent, {
      width: '700px',
      data: {
        menu: this.MenuDetails,
        preorder: preorder,
        business: business
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.preorderDetails.preorder === false) {
        this.preorderDetails = result;
      } else if (result.preorder === true) {
        this.preorderDetails = result;
      }
      if (result.preorder === true && this.orderType === '0') {
        this.comservice.set('preorderDetails', this.preorderDetails);

        if (this.preorderDetails.preorder) {
          let dString = new Date(this.preorderDetails.preorderDateString);
          let dayNumber;
          if (dString.getDay() == 0) {
            dayNumber = 7;
          } else {
            dayNumber = dString.getDay();
          }
          if (this.businessDetails?.buyone_getone_days !== null) {
            for(let bday of this.businessDetails?.buyone_getone_days) {
              if (bday.id == dayNumber) {
                if (bday.status == true) {
                  this.businessDetails.buyone_getone_status = true;
                } else {
                  this.businessDetails.buyone_getone_status = false;
                }
              }
            }
          }

        }
        this.comservice.set('orderType', '0');

        this.myClonedcategory = new Array();
        this.productDisplay = false;
        this.buyOneGetOneProduct = new Array();
        this.spinner.show();

        this.apiservice.getBusinessMenu(this.businessDetails.id, 0, this.preorderDetails).subscribe((responseMenu: any) => {
          this.spinner.hide();
          this.categoryDetails = responseMenu.category;
          this.myClonedcategoryDetails = JSON.stringify(this.categoryDetails);
          let myClonedcategory;
          myClonedcategory = JSON.stringify(this.categoryDetails);
          this.myClonedcategory = JSON.parse(myClonedcategory);

          this.catLength = this.categoryDetails.length;
          this.MenuDetails = responseMenu.menu;


          let myClonedcategoryArray;
          myClonedcategoryArray = JSON.parse(myClonedcategory);
          myClonedcategoryArray.forEach((element, index) => {
            this.apiservice.getProductByCategory(JSON.stringify(element.dishId), this.orderType).subscribe((responseProduct: any) => {
              this.categoryDetails[index].dish = responseProduct;
              this.myClonedcategory[index].dish = responseProduct;

              responseProduct.forEach((productElement, productIndex) => {
                this.MenuDetails.forEach((menuElement) => {
                  if (menuElement.dishes.indexOf(productElement.id) > -1) {
                    if (productElement.dishstatus !== 1) {
                      productElement.dishstatus = menuElement.menustatus;
                    }
                    if (productElement.pickup === undefined) {
                      productElement.pickup = menuElement.pickup;
                    }

                    if (productElement.delivery === undefined) {
                      productElement.delivery = menuElement.delivery;
                    }

                    if (productElement.pickup === '0') {
                      productElement.pickup = menuElement.pickup;
                    }

                    if (productElement.delivery === '0') {
                      productElement.delivery = menuElement.delivery;
                    }

                  }
                });
                element.subCat.forEach((subElement, subIndex) => {
                  if (this.categoryDetails[index].subCat[subIndex].dishId.indexOf(productElement.id) > -1) {
                    this.categoryDetails[index].subCat[subIndex].dish.push(productElement);
                    this.myClonedcategory[index].subCat[subIndex].dish.push(productElement);
                  }
                });
                if (this.businessDetails.buyone_getone_status) {
                  this.buyOneGetOneProduct.push(productElement);
                }
              });
              this.categoryDetails[index].activeDish = true;
              this.myClonedcategory[index].activeDish = true;
              if (this.productDisplay === false) {
                this.productDisplay = true;
              }
            });
          });
        });
      } else if (result.preorder === true && this.orderType === '1') {
        this.deliverySearchAfterContent();
      } else if (result.preorder === true && this.orderType === '2') {
        this.pickupSearch();
      }
    });
  }
  removePreorder() {
    this.preorderDetails = {
      preorder: false,
      preorderDate: '',
      preorderTime: '',
      preorderMenu: 0,
      preorderMenuSchedule: '',
      preorderDateString: ''
    };
    this.comservice.set('preorderDetails', this.preorderDetails);
    location.reload();
  }
  ReviewOpen() {
    $('#myTab1 .rrr a').click();
  }
  addToFav(val) {
    this.userdetails = this.comservice.getFromLocal('user-details');
    if (this.userdetails) {
      if (this.userdetails.id) {
        this.apiservice.setAddToFav(this.userdetails.id, val).subscribe((response: any) => {
          if (response.status === true) {
            this.FavBusiness = true;
          } else {
            this.FavBusiness = false;
          }
        });
      } else {
        this.snackBar.open(this.language.LOGIN_FOR_FAV_BUSINESS, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
    } else {
      this.snackBar.open(this.language.LOGIN_FOR_FAV_BUSINESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
  }
  openProduct(catid, dish) {
    if (dish.spicy === '1' && dish.spicyquantity === undefined) {
      this.snackBar.open(this.language.CHOOSE_SPICY, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    if (this.orderType === '0' || this.orderType === undefined) {
      if (this.businessDetails.is_delivery !== '1' && this.businessDetails.is_pickup === '1') {
        this.from_order = true;
        this.comservice.setService('catid-order', catid);
        this.comservice.setService('dish-order', dish);
        this.prePickupSearch();
        return false;
      }
    }
    if (this.orderType === '0' || this.orderType === undefined) {
      this.from_order = true;
      this.from_order_pos = document.documentElement.scrollTop;
      this.comservice.setService('catid-order', catid);
      this.comservice.setService('dish-order', dish);
      this.deliverySearch();
      return false;
    }
    if (dish.dishstatus !== 1 && this.preorderDetails.preorder === false) {
      this.snackBar.open('Menu Close so can\'t place an order', this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.orderType = '0';
      this.comservice.set('orderType', this.orderType);
      return false;
    }

    if (dish.sets !== undefined) {
      for (const sets of dish.sets) {
        if (sets.options.length > 0) {
          for (const option of sets.options) {
            if (option.choice.length > 0) {
              for (const choice of option.choice) {
                choice.checked = false;
              }
            }
          }
        }
      }
    }

    if (dish.sets.length > 0 || dish.ingredientsarray.length > 0) {
      for (const ingre of dish.ingredientsarray) {
        ingre.status = true;
      }

      const dishdialogRef = this.dialog.open(DishComponent, {
        width: '700px',
        data: {
          dish: dish,
          business: this.businessDetails,
          CartDish: (this.comservice.get('cartDetails') === '') ? new Array() : this.comservice.get('cartDetails'),
          type: 0
        },
        disableClose: true
      });

      dishdialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.status === true) {
            let cart_id;
            cart_id = dish.id;
            $('.cart__animation_' + cart_id).addClass('clicked');
            setTimeout(function() {
              $('.cart__animation_' + cart_id).removeClass('clicked');
            }, 600);
            this.CartDish = result.cartDish;
            if (this.CartDish.length > 0) {
              this.comservice.set('cartDetails', this.CartDish);
              this.calCartTotal();
              this.dataSharingService.isCartAdded.next(true);
              this.checkSuggestProductArray();
            }
          }
        }
      });
    } else {
      this.addToCart(dish);
    }

  }
  addToCart(dishTemp) {
    this.dish = dishTemp;
    this.dishTotal = this.dish.price;

    let bDeatilsCloned;
    bDeatilsCloned = JSON.stringify(this.businessDetails);
    let bDeatils;
    bDeatils = JSON.parse(bDeatilsCloned);
    bDeatils.street = JSON.parse(bDeatils.street);
    bDeatils.type = JSON.parse(bDeatils.type);
    bDeatils.location = JSON.parse(bDeatils.location);
    bDeatils.is_img = JSON.parse(bDeatils.is_img);
    bDeatils.is_banner = JSON.parse(bDeatils.is_banner);
    bDeatils.cuisine = JSON.parse(bDeatils.cuisine);

    let business;
    business = {
      id: this.businessDetails.id,
      details: bDeatils,
      dish: new Array()
    };

    let dish;
    dish = {
      'id': this.dish.id,
      'category': this.dish.category,
      'quantity': 1,
      'data' : new Array()
    };

    let activeIngredients;
    activeIngredients = new Array();

    let data;
    data = {
      'id': this.dish.id,
      'name': this.dish.name,
      'price': this.dish.price,
      'delprice': this.dish.delprice,
      'pickprice': this.dish.pickprice,
      'notes': this.dish.notes,
      'totalprice': this.dishTotal,
      'total': this.dishTotal,
      'is_img': this.dish.is_img,
      'img': this.dish.img,
      'spicy': this.dish.spicy,
      'spicyquantity': this.dish.spicyquantity,
      'veg': this.dish.veg,
      'nonveg': this.dish.nonveg,
      'ingredients': this.dish.ingredients,
      'ingredientsarray': this.dish.ingredientsarray,
      'activeIngredients': activeIngredients,
      'quantity': 1,
      'sets': new Array(),
      'relation': {
        'options': new Array(),
        'choices': new Array()
      },
      'free': {}
    };

    let parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    if (parent_index === -1) {
      this.CartDish.push(business);
    }
    parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    if (this.businessDetails.buyone_getone_status && this.dish.freeitem.length > 0) {
      dish.data.push(data);
      this.CartDish[parent_index].dish.push(dish);

      console.log(this.CartDish);


      const buygetDialogRef = this.dialog.open(BuygetComponent, {
        width: '700px',
        data: {
          productList: this.buyOneGetOneProduct,
          businessDetails: this.businessDetails,
          CartDish: this.CartDish,
          freeItem: this.dish.freeitem
        },
        disableClose: true
      });

      buygetDialogRef.afterClosed().subscribe(result => {
        this.calCartTotal();
      });
    } else {
      let index;
      index = this.CheckAddtoCart(data.id, parent_index);
      if (index === -1) {
        dish.data.push(data);
        this.CartDish[parent_index].dish.push(dish);
      } else {
        this.CartDish[parent_index].dish[index].quantity = parseInt(this.CartDish[parent_index].dish[index].quantity, 10) + 1;
        if (data.sets.length === 0) {
          let index_data;
          index_data = this.CheckData(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        } else {
          let index_data;
          index_data = this.CheckDataSetExist(this.CartDish[parent_index].dish[index].data, data);
          if (index_data === -1) {
            this.CartDish[parent_index].dish[index].data.push(data);
          } else {
            this.CartDish[parent_index].dish[index].data[index_data].notes = this.dish.notes;
            this.CartDish[parent_index].dish[index].data[index_data].quantity = parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10) + 1;
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].totalprice) * parseInt(this.CartDish[parent_index].dish[index].data[index_data].quantity, 10);
            this.CartDish[parent_index].dish[index].data[index_data].total = parseFloat(this.CartDish[parent_index].dish[index].data[index_data].total).toFixed(2);
          }
        }
      }
    }



    let cart_id;
    cart_id = this.dish.id;
    $('.cart__animation_' + cart_id).addClass('clicked');
    setTimeout(function() {
      $('.cart__animation_' + cart_id).removeClass('clicked');
    }, 600);
    if (this.CartDish.length > 0) {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }
  }
  calCartTotal() {
    this.CartSubTotal = 0.00;
    console.log(this.CartDish);
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
          if (this.CartDish[i].dish[j].data[k].total) {
            this.CartSubTotal = parseFloat(this.CartSubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].total);
            this.CartSubTotal = parseFloat(this.CartSubTotal).toFixed(2);

            //new buy one get one option

              if(this.CartDish[i].dish[j].data[k].free){
                if(this.CartDish[i].dish[j].data[k].free.data){
                  for (let f = 0; f < this.CartDish[i].dish[j].data[k].free.data.length; f++) {
                      this.CartSubTotal = parseFloat(this.CartSubTotal) + parseFloat(this.CartDish[i].dish[j].data[k].free.data[f].total);
                      this.CartSubTotal = parseFloat(this.CartSubTotal).toFixed(2);
                  }
                }  
              }
            //end buy one get one option


          }
        }
      }
    }
    this.PendingMinimumFee = this.businessDetails.minimumfee;
    if (this.CartDish.length > 0) {
      if (this.CartDish[0].id === this.businessDetails.id) {
        if (this.PendingMinimumFee > 0) {
          if (this.CartDish.length === 0) {
            this.PendingMinimumFee = parseFloat(this.PendingMinimumFee) - parseFloat(this.CartSubTotal);
            this.PendingMinimumFee = parseFloat(this.PendingMinimumFee).toFixed(2);
          } else {
            if (this.CartDish[0].id === this.businessDetails.id) {
              this.PendingMinimumFee = parseFloat(this.PendingMinimumFee) - parseFloat(this.CartSubTotal);
              this.PendingMinimumFee = parseFloat(this.PendingMinimumFee).toFixed(2);
            }
          }
        }

        if (this.businessDetails.freedeliverystatus === '1') {
          if (parseFloat(this.CartSubTotal) >= parseFloat(this.businessDetails.freedeliveryvalue)) {
            this.PendingFreeDelivery = true;
            this.PendingFreeDeliveryValue = 0.00;
          } else {
            this.PendingFreeDelivery = false;
            this.PendingFreeDeliveryValue = parseFloat(this.businessDetails.freedeliveryvalue) - parseFloat(this.CartSubTotal);
            this.PendingFreeDeliveryValue = parseFloat(this.PendingFreeDeliveryValue).toFixed(2);
          }
        }
      }
    }
  }
  prePickupSearch() {
    let dish;
    dish = this.comservice.getService('dish-order');
    if (this.dishCheckBeforeOrderType(dish.id, 2) !== -1) {
      for (let cat of this.categoryDetails) {
        if (cat.id === this.comservice.getService('catid-order')) {
          for (let cdish of cat.dish) {
            if (cdish.id === dish.id) {
              this.from_order = false;
              cdish.spicyquantity = dish.spicyquantity;
              this.comservice.setService('dish-order', cdish);


              let location;
              location = JSON.parse(this.businessDetails.location);
              let Search;
              Search = {
                lat: location.latitud,
                lng: location.longitud,
                search_address: this.businessDetails.address
              };
              this.comservice.set('Search', Search);
              this.orderType = '2';
              this.comservice.set('orderType', this.orderType);
              this.businessDetails.minimumfee = this.businessDetails.minimumfeeback;
              let valDetails;
              valDetails = {
                minimum: this.businessDetails.minimumfee
              };
              this.comservice.set('deliveryDetails', valDetails);
              this.businessDetails.delivery = 0;

              this.openProduct(this.comservice.getService('catid-order'), this.comservice.getService('dish-order'));
              this.pickupSearch();
              let anchorDiv;
              anchorDiv = $('#anchorDiv').outerHeight( true );
              anchorDiv = (anchorDiv + 30);
              $('html, body').animate({
                scrollTop: this.from_order_pos - anchorDiv
              }, 1000);
            }
          }
        }
      }
    } else {
      let location;
      location = JSON.parse(this.businessDetails.location);
      let Search;
      Search = {
        lat: location.latitud,
        lng: location.longitud,
        search_address: this.businessDetails.address
      };
      this.comservice.set('Search', Search);
      this.orderType = '2';
      this.comservice.set('orderType', this.orderType);
      this.businessDetails.minimumfee = this.businessDetails.minimumfeeback;
      let valDetails;
      valDetails = {
        minimum: this.businessDetails.minimumfee
      };
      this.comservice.set('deliveryDetails', valDetails);
      this.businessDetails.delivery = 0;
      this.pickupSearch();
      let anchorDiv;
      anchorDiv = $('#anchorDiv').outerHeight( true );
      anchorDiv = (anchorDiv + 30);
      $('html, body').animate({
        scrollTop: this.from_order_pos - anchorDiv
      }, 1000);
      this.from_order = false;
    }
  }
  deliverySearch() {
    const dialogRef = this.dialog.open(WhereruComponent, {
      width: '700px',
      data: {
        business: this.businessDetails
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === false) {
        this.orderType = this.comservice.get('orderType');
      } else if (result.order === '2') {
        if (this.from_order === true) {
          let dish;
          dish = this.comservice.getService('dish-order');
          if (this.dishCheckBeforeOrderType(dish.id, 2) !== -1) {
            for (let cat of this.categoryDetails) {
              if (cat.id === this.comservice.getService('catid-order')) {
                for (let cdish of cat.dish) {
                  if (cdish.id === dish.id) {
                    cdish.spicyquantity = dish.spicyquantity;
                    this.comservice.setService('dish-order', cdish);


                    let location;
                    location = JSON.parse(this.businessDetails.location);
                    let Search;
                    Search = {
                      lat: location.latitud,
                      lng: location.longitud,
                      search_address: this.businessDetails.address
                    };
                    this.comservice.set('Search', Search);
                    this.orderType = '2';
                    this.comservice.set('orderType', this.orderType);
                    this.businessDetails.minimumfee = this.businessDetails.minimumfeeback;
                    let valDetails;
                    valDetails = {
                      minimum: this.businessDetails.minimumfee
                    };
                    this.comservice.set('deliveryDetails', valDetails);
                    this.businessDetails.delivery = 0;
                    this.openProduct(this.comservice.getService('catid-order'), this.comservice.getService('dish-order'));
                    this.pickupSearch();

                    let anchorDiv;
                    anchorDiv = $('#anchorDiv').offset().top;
                    anchorDiv = (anchorDiv + 30);
                    $('html, body').animate({
                      scrollTop: anchorDiv
                    }, 1000);
                  }
                }
              }
            }
          } else {
            let location;
            location = JSON.parse(this.businessDetails.location);
            let Search;
            Search = {
              lat: location.latitud,
              lng: location.longitud,
              search_address: this.businessDetails.address
            };
            this.comservice.set('Search', Search);
            this.orderType = '2';
            this.comservice.set('orderType', this.orderType);
            this.businessDetails.minimumfee = this.businessDetails.minimumfeeback;
            let valDetails;
            valDetails = {
              minimum: this.businessDetails.minimumfee
            };
            this.comservice.set('deliveryDetails', valDetails);
            this.businessDetails.delivery = 0;
            this.pickupSearch();

            let anchorDiv;
            anchorDiv = $('#anchorDiv').offset().top;
            anchorDiv = (anchorDiv + 30);
            $('html, body').animate({
              scrollTop: anchorDiv
            }, 1000);
          }
        } else {
          let location;
            location = JSON.parse(this.businessDetails.location);
            let Search;
            Search = {
              lat: location.latitud,
              lng: location.longitud,
              search_address: this.businessDetails.address
            };
            this.comservice.set('Search', Search);
            this.orderType = '2';
            this.comservice.set('orderType', this.orderType);
            this.businessDetails.minimumfee = this.businessDetails.minimumfeeback;
            let valDetails;
            valDetails = {
              minimum: this.businessDetails.minimumfee
            };
            this.comservice.set('deliveryDetails', valDetails);
            this.businessDetails.delivery = 0;
            this.pickupSearch();
        }
      } else if (result.order === '1') {
        if (this.from_order === true) {
          let dish;
          dish = this.comservice.getService('dish-order');
          if (this.dishCheckBeforeOrderType(dish.id, 1) !== -1) {
            for (let cat of this.categoryDetails) {
              if (cat.id === this.comservice.getService('catid-order')) {
                for (let cdish of cat.dish) {
                  if (cdish.id === dish.id) {
                    cdish.spicyquantity = dish.spicyquantity;
                    this.comservice.setService('dish-order', cdish);
                    this.comservice.set('Search', result.Search);
                    this.orderType = '1';
                    this.comservice.set('orderType', this.orderType);
                    if (result !== undefined) {
                      this.businessDetails.minimumfee = result.details.minimum;
                      this.businessDetails.delivery = result.details.delivery;
                      this.comservice.set('deliveryDetails', result.details);
                    }
                    let anchorDiv;
                    anchorDiv = $('#anchorDiv').offset().top;
                    anchorDiv = (anchorDiv + 30);
                    $('html, body').animate({
                      scrollTop: anchorDiv
                    }, 1000);
                    this.openProduct(this.comservice.getService('catid-order'), this.comservice.getService('dish-order'));
                    this.deliverySearchAfterContent();
                  }
                }
              }
            }
          } else {
            this.comservice.set('Search', result.Search);
            this.orderType = '1';
            this.comservice.set('orderType', this.orderType);
            this.businessDetails.minimumfee = result.details.minimum;
            this.businessDetails.delivery = result.details.delivery;
            this.comservice.set('deliveryDetails', result.details);
            $('html, body').animate({
              scrollTop: this.from_order_pos
            }, 1000);
            this.deliverySearchAfterContent();
            this.from_order = false;
            let anchorDiv;
            anchorDiv = $('#anchorDiv').offset().top;
            anchorDiv = (anchorDiv + 30);
            $('html, body').animate({
              scrollTop: anchorDiv
            }, 1000);
          }
        } else {
          this.comservice.set('Search', result.Search);
          this.orderType = '1';
          this.comservice.set('orderType', this.orderType);
          this.businessDetails.minimumfee = result.details.minimum;
          this.businessDetails.delivery = result.details.delivery;
          this.comservice.set('deliveryDetails', result.details);
          this.deliverySearchAfterContent();
        }
      }
    });
  }
  dishCheckBeforeOrderType(id, type) {
    for (let k = 0; k < this.categoryDetails.length; k++) {
      for (let l = 0; l < this.categoryDetails[k].dish.length; l++) {
        if (type === 1) {
          if (this.categoryDetails[k].dish[l].id === id && this.categoryDetails[k].dish[l].delivery === '1') {
            return l;
          }
        }
        if (type === 2) {
          if (this.categoryDetails[k].dish[l].id === id && this.categoryDetails[k].dish[l].pickup === '1') {
            return l;
          }
        }
      }
    }
    return -1;
  }
  deliverySearchAfterContent() {
    this.spinner.show();
    this.businessDetails = this.comservice.getService('businessDetails');

    if (this.preorderDetails.preorder) {
      let dString = new Date(this.preorderDetails.preorderDateString);
      let dayNumber;
      if (dString.getDay() == 0) {
        dayNumber = 7;
      } else {
        dayNumber = dString.getDay();
      }
      if (this.businessDetails?.buyone_getone_days !== null) {
        for(let bday of this.businessDetails.buyone_getone_days) {
          if (bday.id == dayNumber) {
            if (bday.status == true) {
              this.businessDetails.buyone_getone_status = true;
            } else {
              this.businessDetails.buyone_getone_status = false;
            }
          }
        }
      }
    }

    this.apiservice.getBusinessMenu(this.businessDetails.id, 1, this.preorderDetails).subscribe((responseMenu: any) => {
      this.spinner.hide();
      if (Object.keys(responseMenu.menu).length === 0) {
        this.snackBar.open(this.language.NOT_HAVE_ACTIVE_PICKUP_MENU, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.orderType = '0';
        this.comservice.set('orderType', this.orderType);
        return false;
      } else {
        this.buyOneGetOneProduct = new Array();
        this.productDisplay = false;
        this.categoryDetails = responseMenu.category;
        this.myClonedcategoryDetails = JSON.stringify(this.categoryDetails);
        let myClonedcategory;
        myClonedcategory = JSON.stringify(this.categoryDetails);
        this.myClonedcategory = JSON.parse(myClonedcategory);
        let catLength;
        this.catLength = this.categoryDetails.length;
        catLength = this.categoryDetails.length;
        this.MenuDetails = responseMenu.menu;


        let myClonedcategoryArray;
        myClonedcategoryArray = JSON.parse(myClonedcategory);
        myClonedcategoryArray.forEach((element, index) => {
          this.apiservice.getProductByCategory(JSON.stringify(element.dishId), this.orderType).subscribe((responseProduct: any) => {
            this.categoryDetails[index].dish = responseProduct;
            this.myClonedcategory[index].dish = responseProduct;
            responseProduct.forEach((productElement, productIndex) => {
              this.MenuDetails.forEach((menuElement) => {
                if (menuElement.dishes.indexOf(productElement.id) > -1) {
                  if (productElement.dishstatus !== 1) {
                    productElement.dishstatus = menuElement.menustatus;
                  }
                  if (productElement.pickup === undefined) {
                    productElement.pickup = menuElement.pickup;
                  }

                  if (productElement.delivery === undefined) {
                    productElement.delivery = menuElement.delivery;
                  }

                  if (productElement.pickup === '0') {
                    productElement.pickup = menuElement.pickup;
                  }

                  if (productElement.delivery === '0') {
                    productElement.delivery = menuElement.delivery;
                  }


                }
              });
              element.subCat.forEach((subElement, subIndex) => {
                if (this.categoryDetails[index].subCat[subIndex].dishId.indexOf(productElement.id) > -1) {
                  this.categoryDetails[index].subCat[subIndex].dish.push(productElement);
                  this.myClonedcategory[index].subCat[subIndex].dish.push(productElement);
                }
              });

              if (this.businessDetails.buyone_getone_status) {
                this.buyOneGetOneProduct.push(productElement);
              }
            });
            this.categoryDetails[index].activeDish = true;
            this.myClonedcategory[index].activeDish = true;
            if (this.productDisplay === false) {
              this.productDisplay = true;
            }
            catLength--;
            if (catLength === 0) {
              this.comservice.setService('businessDetails', this.businessDetails);
              if (this.CartDish.length > 0) {
                if (this.CartDish[0].id === this.businessDetails.id) {
                  this.cartCheck();
                }
              } else {
                this.cartCheck();
              }

              if (this.from_order) {
                let dish;
                dish = this.comservice.getService('dish-order');
                let divId;
                divId = 'anchor' + dish.category;
                if (dish.subcategory > 0) {
                  divId = divId + '_' + dish.subcategory;
                }
                if ($('#' + divId)) {
                  let dishInterval;
                  dishInterval = setInterval(function() {
                    let anchorDiv;
                    anchorDiv = $('#anchorDiv').outerHeight( true );
                    anchorDiv = (anchorDiv + 15);
                    $('html, body').animate({
                      scrollTop: $('#' + divId).offset().top - anchorDiv
                    }, 1000);
                    this.from_order = false;
                    clearInterval(dishInterval);
                  }, 1000);
                }
              }
            }
          });
        });
      }
    });
  }
  prePickupSearchHtml() {
    let location;
    location = JSON.parse(this.businessDetails.location);
    let Search;
    Search = {
      lat: location.latitud,
      lng: location.longitud,
      search_address: this.businessDetails.address
    };
    this.comservice.set('Search', Search);
    this.orderType = '2';
    this.comservice.set('orderType', this.orderType);
    this.businessDetails.minimumfee = this.businessDetails.minimumfeeback;
    let valDetails;
    valDetails = {
      minimum: this.businessDetails.minimumfee
    };
    this.comservice.set('deliveryDetails', valDetails);
    this.businessDetails.delivery = 0;

    this.pickupSearch();
  }
  pickupSearch() {
    this.myClonedcategory = new Array();
    this.spinner.show();
    this.businessDetails = this.comservice.getService('businessDetails');
    if (this.preorderDetails.preorder) {
      let dString = new Date(this.preorderDetails.preorderDateString);
      let dayNumber;
      if (dString.getDay() == 0) {
        dayNumber = 7;
      } else {
        dayNumber = dString.getDay();
      }
      if (this.businessDetails?.buyone_getone_days !== null) {
        for(let bday of this.businessDetails?.buyone_getone_days) {
          if (bday.id == dayNumber) {
            if (bday.status == true) {
              this.businessDetails.buyone_getone_status = true;
            } else {
              this.businessDetails.buyone_getone_status = false;
            }
          }
        }
      } else {
        this.businessDetails.buyone_getone_status = false;
      }
    }

    this.apiservice.getBusinessMenu(this.businessDetails.id, 2, this.preorderDetails).subscribe((responseMenu: any) => {
      this.spinner.hide();
      if (Object.keys(responseMenu.menu).length === 0) {
        this.snackBar.open(this.language.NOT_HAVE_ACTIVE_PICKUP_MENU, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.orderType = '0';
        this.comservice.set('orderType', this.orderType);
        return false;
      } else {

        this.buyOneGetOneProduct = new Array();
        this.productDisplay = false;
        this.categoryDetails = responseMenu.category;
        this.myClonedcategoryDetails = JSON.stringify(this.categoryDetails);
        let myClonedcategory;
        myClonedcategory = JSON.stringify(this.categoryDetails);
        this.myClonedcategory = JSON.parse(myClonedcategory);
        let catLength;
        this.catLength = this.categoryDetails.length;
        catLength = this.categoryDetails.length;
        this.MenuDetails = responseMenu.menu;


        let myClonedcategoryArray;
        myClonedcategoryArray = JSON.parse(myClonedcategory);
        myClonedcategoryArray.forEach((element, index) => {
          this.apiservice.getProductByCategory(JSON.stringify(element.dishId), this.orderType).subscribe((responseProduct: any) => {
            this.categoryDetails[index].dish = responseProduct;
            this.myClonedcategory[index].dish = responseProduct;
            responseProduct.forEach((productElement, productIndex) => {
              this.MenuDetails.forEach((menuElement) => {
                if (menuElement.dishes.indexOf(productElement.id) > -1) {
                  if (productElement.dishstatus !== 1) {
                    productElement.dishstatus = menuElement.menustatus;
                  }
                  if (productElement.pickup === undefined) {
                    productElement.pickup = menuElement.pickup;
                  }

                  if (productElement.delivery === undefined) {
                    productElement.delivery = menuElement.delivery;
                  }

                  if (productElement.pickup === '0') {
                    productElement.pickup = menuElement.pickup;
                  }

                  if (productElement.delivery === '0') {
                    productElement.delivery = menuElement.delivery;
                  }


                }
              });
              element.subCat.forEach((subElement, subIndex) => {
                if (this.categoryDetails[index].subCat[subIndex].dishId.indexOf(productElement.id) > -1) {
                  this.categoryDetails[index].subCat[subIndex].dish.push(productElement);
                  this.myClonedcategory[index].subCat[subIndex].dish.push(productElement);
                }
              });
              if (this.businessDetails.buyone_getone_status) {
                this.buyOneGetOneProduct.push(productElement);
              }
            });
            this.categoryDetails[index].activeDish = true;
            this.myClonedcategory[index].activeDish = true;
            if (this.productDisplay === false) {
              this.productDisplay = true;
            }
            catLength--;
            if (catLength === 0) {
              this.comservice.setService('businessDetails', this.businessDetails);
              if (this.CartDish.length > 0) {
                if (this.CartDish[0].id === this.businessDetails.id) {
                  this.cartCheck();
                }
              } else {
                this.cartCheck();
              }

              if (this.from_order) {
                let dish;
                dish = this.comservice.getService('dish-order');
                let divId;
                divId = 'anchor' + dish.category;
                if (dish.subcategory > 0) {
                  divId = divId + '_' + dish.subcategory;
                }
                if ($('#' + divId)) {
                  let dishInterval;
                  dishInterval = setInterval(function() {
                    let anchorDiv;
                    anchorDiv = $('#anchorDiv').outerHeight( true );
                    anchorDiv = (anchorDiv + 15);
                    $('html, body').animate({
                      scrollTop: $('#' + divId).offset().top - anchorDiv
                    }, 1000);
                    this.from_order = false;
                    clearInterval(dishInterval);
                  }, 1000);
                }
              }
            }
          });
        });

      }
    });
  }
  checkCategoryExist(id, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        return id;
      }
    }
    return -1;
  }
  cartCheck() {
    for (let i = 0; i < this.CartDish.length; i++) {
      for (let j = 0; j < this.CartDish[i].dish.length; j++) {
        let index;
        index = this.dishCheck(this.CartDish[i].dish[j].id);
        if (index === -1) {
          this.CartDish[i].dish.splice(j, 1);
          if (this.CartDish.length === 0) {
            this.CartDish.splice(i, 1);
          }
          this.calCartTotal();

          this.comservice.set('cartDetails', this.CartDish);

          this.dataSharingService.isCartAdded.next(true);
        } else {
          for (let k = 0; k < this.CartDish[i].dish[j].data.length; k++) {
            let p_price = parseFloat(this.CartDish[i].dish[j].data[k].totalprice) - parseFloat(this.CartDish[i].dish[j].data[k].price);
            p_price = Number(p_price);
            let catindex;
            catindex = this.catCheck(this.CartDish[i].dish[j].id);
            this.CartDish[i].dish[j].data[k].price = this.categoryDetails[catindex].dish[index].price;
            this.CartDish[i].dish[j].data[k].price = parseFloat(this.CartDish[i].dish[j].data[k].price).toFixed(2);
            this.CartDish[i].dish[j].data[k].totalprice = parseFloat(this.CartDish[i].dish[j].data[k].price) + p_price;
            this.CartDish[i].dish[j].data[k].totalprice = parseFloat(this.CartDish[i].dish[j].data[k].totalprice).toFixed(2);
            this.CartDish[i].dish[j].data[k].total = parseFloat(this.CartDish[i].dish[j].data[k].totalprice) * parseInt(this.CartDish[i].dish[j].data[k].quantity, 10);
            this.CartDish[i].dish[j].data[k].total = parseFloat(this.CartDish[i].dish[j].data[k].total).toFixed(2);
          }
          this.calCartTotal();

          this.comservice.set('cartDetails', this.CartDish);

          this.dataSharingService.isCartAdded.next(true);
        }
      }
    }
  }
  dishCheck(id) {
    for (let k = 0; k < this.categoryDetails.length; k++) {
      for (let l = 0; l < this.categoryDetails[k].dish.length; l++) {
        if (this.categoryDetails[k].dish[l].id === id) {
          return l;
        }
      }
    }
    return -1;
  }
  catCheck(id) {
    for (let k = 0; k < this.categoryDetails.length; k++) {
      for (let l = 0; l < this.categoryDetails[k].dish.length; l++) {
        if (this.categoryDetails[k].dish[l].id === id) {
          return k;
        }
      }
    }
    return -1;
  }
  addTocartIncrement(index, dishindex, dishdataindex) {
    this.CartDish = this.comservice.get('cartDetails');
    this.CartDish[index].dish[dishindex].quantity = this.CartDish[index].dish[dishindex].quantity + 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].quantity = this.CartDish[index].dish[dishindex].data[dishdataindex].quantity + 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].totalprice) * parseInt(this.CartDish[index].dish[dishindex].data[dishdataindex].quantity, 10);
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].total).toFixed(2);
    this.calCartTotal();
    this.comservice.set('cartDetails', this.CartDish);
    this.dataSharingService.isCartAdded.next(true);
  }
  addTocartDecrement(index, dishindex, dishdataindex) {
    this.CartDish = this.comservice.get('cartDetails');
    this.CartDish[index].dish[dishindex].quantity = this.CartDish[index].dish[dishindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].quantity = this.CartDish[index].dish[dishindex].data[dishdataindex].quantity - 1;
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].totalprice) * parseInt(this.CartDish[index].dish[dishindex].data[dishdataindex].quantity, 10);
    this.CartDish[index].dish[dishindex].data[dishdataindex].total = parseFloat(this.CartDish[index].dish[dishindex].data[dishdataindex].total).toFixed(2);
    if (this.CartDish[index].dish[dishindex].data[dishdataindex].quantity === 0) {
      this.CartDish[index].dish[dishindex].data.splice(dishdataindex, 1);
      if (this.CartDish[index].dish[dishindex].data.length === 0) {
        this.CartDish[index].dish.splice(dishindex, 1);
      }
      if (this.CartDish[index].dish.length === 0) {
        this.CartDish.splice(index, 1);
      }
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
      this.checkSuggestProductArray();
    } else {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }
    this.calCartTotal();
  }
  editProduct(index, dishindex, dishdataindex) {
    this.indexcart = {
      'index': index,
      'dishindex': dishindex,
      'dishdataindex': dishdataindex,
      'EDITPRODUCTCART': 1,
      'dishTotal': 0
    };
    let cindex;
    cindex = this.dishCheck(this.CartDish[index].dish[dishindex].id);
    let catindex;
    catindex = this.catCheck(this.CartDish[index].dish[dishindex].id);
    let dish;
    dish = this.categoryDetails[catindex].dish[cindex];
    this.indexcart.dishTotal = this.CartDish[index].dish[dishindex].data[dishdataindex].total;

    if (dish.sets !== undefined) {
      for (let i = 0; i < dish.sets.length; i++) {
        for (let j = 0; j < dish.sets[i].options.length; j++) {
          for (let k = 0; k < dish.sets[i].options[j].choice.length; k++) {
            let a;
            a = this.CartDish[index].dish[dishindex].data[dishdataindex].relation.choices.indexOf(dish.sets[i].options[j].choice[k].id);
            if (a !== -1) {
              dish.sets[i].options[j].choice[k].checked = true;
              if (dish.sets[i].options[j].show === false) {
                dish.sets[i].options[j].show = true;
              }
            } else {
              dish.sets[i].options[j].choice[k].checked = false;
            }
          }
        }
      }
    }

    dish.notes = this.CartDish[index].dish[dishindex].data[dishdataindex].notes;
    dish.ingredientsarray = this.CartDish[index].dish[dishindex].data[dishdataindex].ingredientsarray;
    if (this.CartDish[index].dish[dishindex].data[dishdataindex].activeIngredients) {
      dish.activeIngredients = this.CartDish[index].dish[dishindex].data[dishdataindex].activeIngredients;
    }
    dish.spicyquantity = this.CartDish[index].dish[dishindex].data[dishdataindex].spicyquantity;

    const dishdialogRef = this.dialog.open(DishComponent, {
      width: '700px',
      data: {
        dish: dish,
        business: this.businessDetails,
        CartDish: (this.comservice.get('cartDetails') === '') ? new Array() : this.comservice.get('cartDetails'),
        indexCart: this.indexcart,
        type: 1
      },
      disableClose: true
    });

    dishdialogRef.afterClosed().subscribe(result => {
      if (result.status === true) {
        this.CartDish = [];
        this.CartDish = result.cartDish;
        if (this.CartDish.length > 0) {
          this.comservice.set('cartDetails', this.CartDish);
          this.calCartTotal();
        }
      }
    });
  }
  checkSuggestProductArray() {
    this.CartDish = this.comservice.get('cartDetails');
    this.suggestProduct = [];
    this.comservice.setService('suggestProduct', this.suggestProduct);
    for (let cart of this.CartDish) {
      for (let cdish of cart.dish) {
        for (let cat of this.myClonedcategory) {
          for (let dish of cat.dish) {
            if (cdish.id === dish.id && dish.suggestprod === true) {
              for (let sdish of dish.suggestion) {
                if (this.suggestProduct.indexOf(sdish) === -1) {
                  this.suggestProduct.push(sdish);
                }
              }
              this.comservice.setService('suggestProduct', this.suggestProduct);
            }
          }
        }
      }
    }
    for (let cart of this.CartDish) {
      for (let cdish of cart.dish) {
        let cindex;
        cindex = this.suggestProduct.indexOf(cdish.id);
        if (cindex !== -1) {
          this.suggestProduct.splice(cindex, 1);
          this.comservice.setService('suggestProduct', this.suggestProduct);
        }
      }
    }
  }
  checkSuggestProduct() {
    this.checkSuggestProductArray();
    this.suggestProduct = this.comservice.getService('suggestProduct');
    let suggestProductDetails;
    suggestProductDetails = new Array();

    for (let cat of this.myClonedcategory) {
      let category;
      category = {
        id: cat.id,
        name: cat.name,
        rank: cat.rank,
        subCat: cat.subCat,
        dish: []
      };
      for (let dish of cat.dish) {
        if (this.suggestProduct.indexOf(dish.id) !== -1) {
          category.dish.push(dish);
        }
      }
      if (category.dish.length > 0) {
        suggestProductDetails.push(category);
      }
    }
    if (suggestProductDetails.length > 0) {
      const suggestDialogRef = this.dialog.open(SuggestionComponent, {
        width: '700px',
        data: {
          suggestProduct: suggestProductDetails,
          businessDetails: this.businessDetails,
          CartDish: (this.comservice.get('cartDetails') === '') ? new Array() : this.comservice.get('cartDetails')
        },
        disableClose: true
      });

      suggestDialogRef.afterClosed().subscribe(result => {
        this.goToCheckout();
      });
    } else {
      this.goToCheckout();
    }
  }
  pregoToCheckout(val) {
    let orderType;
    orderType = this.comservice.get('orderType');
    if (orderType === '0') {
      this.snackBar.open(this.language.CHOOSE_ORDER_TYPE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    } else if (orderType === '1' || orderType === '2') {
      if (val === '1') {
        this.checkSuggestProduct();
      }
      if (val === '2') {
        this.checkRequiredMinimum();
      }
    }

  }
  goToCheckout() {
    this.comservice.scrollTop();
    this.comservice.setService('businessDetails', this.businessDetails);
    this.comservice.set('cartDetails', this.CartDish);
    this.comservice.setService('categoryDetails', this.categoryDetails);
    this.comservice.setService('myClonedcategoryDetails', this.myClonedcategoryDetails);
    this.comservice.setService('MenuDetails', this.MenuDetails);
    this.comservice.set('orderType', this.orderType);
    this.comservice.set('preorderDetails', this.preorderDetails);

    let val;
    let uid;
    uid = this.comservice.getFromLocal('userId');
    if (uid > 0) {
      this.comservice.setService('prevroute', 'checkout');
      val = 'checkout';
      this.router.navigate(['/' + val]);
    } else {
      this.comservice.setService('prevroute', 'checkout');
      val = 'login';
      const dialogRef = this.dialog.open(LoginComponent, {
        width: '500px',
        data: {},
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }
  goToCartScroll() {
    let anchorDiv;
    anchorDiv = $('#cartscroll').offset().top;
    $('html, body').animate({
      scrollTop: anchorDiv
    }, 1000);
  }
  scrollTab(val) {
    let anchorDiv;
    anchorDiv = $('#anchorDiv').outerHeight( true );
    anchorDiv = (anchorDiv + 15);
    $('html, body').animate({
      scrollTop: $('#anchor' + val).offset().top - anchorDiv
    }, 1000);
  }
  scrollSubTab(valc, vals) {
    let anchorDiv;
    anchorDiv = $('#anchorDiv').outerHeight( true );
    anchorDiv = (anchorDiv + 15);
    $('html, body').animate({
      scrollTop: $('#anchor' + valc + '_' + vals).offset().top - anchorDiv
    }, 1000);
  }
  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value === 1) {
      return 'L';
    } else if (value === 2) {
      return 'M';
    } else if (value === 3) {
      return 'H';
    }

    return value;
  }
  checkSubCatExist(sub) {
    if (sub.length === 0) {
      return true;
    } else {
      var counter = 0;
      for (var i = 0; i < sub.length; i++) {
        if (sub[i].active === false) {
          counter++;
        }
      }
      if (counter === sub.length) {
        return true;
      } else {
        return false;
      }
    }
  }
  checkRequiredMinimum() {
    let deliveryDetails;
    deliveryDetails = this.comservice.get('deliveryDetails');
    if (this.orderType === '1') {
      this.businessDetails.minimumfee = parseFloat(deliveryDetails.minimum);
    }

    if (parseFloat(this.CartSubTotal) >= parseFloat(this.businessDetails.minimumfee)) {
      this.checkSuggestProduct();
    } else {
      this.openRequiredMinimum();
    }
  }
  openRequiredMinimum(): void {
    const dialogRef = this.dialog.open(RequiredminimumComponent, {
      width: '600px',
      data: {
        businessDetails: this.businessDetails,
        subtotal: this.CartSubTotal
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === true) {
        this.checkSuggestProduct();
      }
    });
  }


  CheckBusinessAddtoCart(d) {
    let b;
    b = this.CartDish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckAddtoCart(d, index) {
    let b;
    b = this.CartDish[index].dish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  CheckData(cartdata, data) {
    if (data.sets.length === 0) {
      for (let i = 0; i < cartdata.length; i++) {
        if (cartdata[i].sets.length === 0) {
          return i;
        }
      }
      return -1;
    }
  }
  CheckDataSetExist(cartdata, data) {
    for (let i = 0; i < cartdata.length; i++) {
      if (cartdata[i].relation) {
        let arrayDiffoptions;
        arrayDiffoptions = this.arrayDiff(cartdata[i].relation.options, data.relation.options);
        let arrayDiffchoice;
        arrayDiffchoice = this.arrayDiff(cartdata[i].relation.choices, data.relation.choices);
        if (arrayDiffoptions.arr1.length === 0 && arrayDiffoptions.arr2.length === 0 && arrayDiffchoice.arr1.length === 0 && arrayDiffchoice.arr2.length === 0) {
          return i;
        }
      }
    }
    return -1;
  }
  arrayDiff(arr1, arr2) {
    let diff;
    diff = {
      'arr1': '',
      'arr2': '',
      'concat': ''
    };

    diff.arr1 = arr1.filter(function (value) {
      if (arr2.indexOf(value) === -1) {
        return value;
      }
    });

    diff.arr2 = arr2.filter(function (value) {
      if (arr1.indexOf(value) === -1) {
        return value;
      }
    });

    diff.concat = diff.arr1.concat(diff.arr2);

    return diff;
  }
}
