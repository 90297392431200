
<div *ngIf="appType">
  <div class="banner" [lazyLoad]="bannerImage">
    <div class="banner-lfy">
      <a href="index.html"><img [lazyLoad]="logoImage" /></a>
      <h4>{{language.SITE_SLOGAN}}</h4>
      <div class="search">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-addon">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <input type="text" [(ngModel)]="searchaddress" (keyup)="PlaceChanged()"
              placeholder="{{language.ADDRESSBAR_PLACEHOLDER}}" autocorrect="off" autocapitalize="off" spellcheck="off"
              class="form-control" (keyup.enter)="preSearchNow()" #search>
            <div class="input-group-addon src-now" (click)="preSearchNow()">
              <a ng-href="javascript:void(0)">{{language.SEARCH}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="register">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="rgst-now">
            <h4>{{language.REGISTER_BUSINESS_ON_MENUHUTS}}</h4>
            <p>{{language.REGISTER_BUSINESS_ON_MENUHUTS_DESCR}} <a
                href="mailto:sales@menuhuts.com">{{language.REGISTER_BUSINESS_ON_MENUHUTS_DESCR_LINK}}</a>
              {{language.REGISTER_BUSINESS_ON_MENUHUTS_DESCR1}}</p>
            <button class="cmn-button" (click)="pregotoRegister()">{{language.REGISTER_NOW}}
            </button>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12"> </div>
      </div>
      <div class="row">
        <div class="col-md-12-col=sm-12 col-xs-12">
          <div class="rg-pic">
            <img [lazyLoad]="registerImage" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <section id="cty-cuisn" [lazyLoad]="cityCuisineImage">
    <div class="container">
      <div class="row">
        <div class="col-md-7 col-sm-6 col-xs-12" *ngIf="listCity">
          <div class="city">
            <h4>{{language.CHOOSE_YOUR_CITY}}
              <div class="cty-drp">
                <select [(ngModel)]="currentCntryCode" (change)="changeCountry($event)">
                  <option *ngFor="let cntry of listCityList" [value]="cntry?.id">{{cntry?.name}}</option>
                </select>
              </div>
            </h4>

            <ul>
              <li *ngFor="let city of cityList | slice:0:17;">
                <a href="/city/{{city?.id}}/{{city?.name}}">
                  <span>
                    <img [lazyLoad]="cityImage" />
                  </span>{{city?.name}}
                </a>
              </li>
              <li *ngIf="cityList.length > 17" class="last-item">
                <a ng-href="#" (click)="cityBrowse();" class="cty-bfr">
                  <span>
                    <img [lazyLoad]="cityImage" />
                  </span>{{language.MORE}}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-5 col-sm-6 col-xs-12" *ngIf="listCuisine">
          <div class="city cuisen">
            <h4>{{language.CHOOSE_CUISINE}}</h4>
            <ul>
              <li *ngFor="let cuisine of listCuisineList | slice:0:17;">
                <a href="/cuisine/{{cuisine?.id}}/{{cuisine?.name}}">
                  <span>
                    <img [lazyLoad]="cuisineImage" />
                  </span>{{cuisine?.name}}
                </a>
              </li>

              <li *ngIf="listCuisineList.length > 17" class="last-item">
                <a ng-href="#" (click)="cuisineBrowse();">
                  <span>
                    <img [lazyLoad]="cuisineImage" />
                  </span>{{language.MORE}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<div *ngIf="!appType">
  <div class="animated-background" *ngIf="!businessLoad">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-sm-12">
          <div class="skl-bdy">
            <div class="skl-lft">
              <div class="skl-logo">
              </div>
              <div class="skl-dtls">
                <div class="skl-hdn"></div>
                <div class="skl-prgf"></div>
                <div class="skl-prgf" style="width: 50%"></div>
                <div class="skl-prgf" style="width: 45%"></div>
                <div class="skl-prgf"></div>
                <ul class="skl-pymnt">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div class="skl-ryt">

              <ul class="skl-adns">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <ng-template #subContainer1></ng-template>
</div>

