import { Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from './../common.service';
import { Globals } from '../globals';

export interface DialogData {
  menu: any;
  preorder: any;
  business: any;
}

@Component({
  selector: 'app-preorder',
  templateUrl: './preorder.component.html',
  styleUrls: ['./preorder.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PreorderComponent {
  public globals: Globals;
  public options = { autoHide: true, scrollbarMinSize: 100 };
  public minDate = new Date();
  public maxDate = new Date();
  public menuTime: any;
  public dateFilter: any;
  public dateClass: any;
  public language: any;
  public menuStatus: any = true;

  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef < PreorderComponent >,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public comservice: CommonService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
    let date;
    date = new Date().toLocaleString('en-US', {
      timeZone: this.data.business.code
    });
    this.minDate = new Date(date);
    this.maxDate.setDate(this.minDate.getDate() + 6);

    if (this.data.menu === undefined) {
      this.menuStatus = false;
    }

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (this.data.preorder.preorderMenu === 0) {
      this.snackBar.open(this.language.SELECT_MENU, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    let d: any;
    d = new Date(event.value);
    this.data.preorder.preorderDate = event.value;
    let day;
    day = (d.getDay() === 0) ? 7 : d.getDay();
    for (let m of this.data.menu) {
      if (m.id === this.data.preorder.preorderMenu) {
        this.data.preorder.preorderMenuSchedule = m.schedule;
        for (let ms of m.schedule) {
          if (day === ms.id) {
            let menuObject;
            menuObject = ms.times;

            this.menuTime = new Array();
            const minutesToAdjust = 15;
            const millisecondsPerMinute = 60000;

            for (let ts of menuObject) {
              let open;
              let close;
              open = ts.opens.hour + ':' + ts.opens.minute;
              close = ts.closes.hour + ':' + ts.closes.minute;

              let now;
              let nowTime;
              let userTime1, userTime2;
              let date;
              date = new Date().toLocaleString('en-US', {
                timeZone: this.data.business.code
              });
              now = new Date(date);
              let mins;
              mins = now.getMinutes();
              let quarterHours;
              quarterHours = Math.round(mins / 15);
              if (quarterHours === 4) {
                  now.setHours(now.getHours() + 1);
              }
              let rounded;
              rounded = (quarterHours * 15) % 60;
              now.setMinutes(rounded);
              nowTime = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes());
              userTime1 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + open);
              userTime2 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + close);
              userTime2 = new Date(userTime2.valueOf() - (minutesToAdjust * millisecondsPerMinute));

              if (day === now.getDay()) {
                if (nowTime.getTime() < userTime1.getTime()) {
                  let time;
                  time = userTime1;
                  while (time.getTime() <= userTime2.getTime()) {
                    let hour;
                    hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                    let minute;
                    minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                    this.menuTime.push(hour + ':' + minute);
                    time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                  }
                } else if (nowTime.getTime() >= userTime1.getTime() && nowTime.getTime() <= userTime2.getTime()) {
                  let time;
                  time = nowTime;
                  time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                  while (time.getTime() <= userTime2.getTime()) {
                    let hour;
                    hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
                    let minute;
                    minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                    this.menuTime.push(hour + ':' + minute);
                    time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                  }
                }
              } else {
                userTime1 = new Date(userTime1.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                let time;
                time = userTime1;
                while (time.getTime() <= userTime2.getTime()) {
                  let hour;
                  hour = (time.getHours() <= 9) ? '0' + time.getHours()  : '' + time.getHours();
                  let minute;
                  minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
                  this.menuTime.push(hour + ':' + minute);
                  time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
                }
              }
            }
            break;
          }
        }
        break;
      }
    }
  }

  onNoClick() {
    if (this.data.menu !== undefined) {
      for (let m of this.data.menu) {
        m.active = false;
      }
    }
    this.data.preorder.preorder = false;
    this.data.preorder.preorderDate = '';
    this.data.preorder.preorderTime = '';
    this.data.preorder.preorderMenu = 0;
    this.data.preorder.preorderMenuSchedule = '';
    this.data.preorder.preorderDateString = '';
    this.dialogRef.close(this.data.preorder);
  }
  onSubmitClick() {
    if (this.data.preorder.preorderMenu === 0) {
      this.snackBar.open(this.language.SELECT_MENU, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    if (this.data.preorder.preorderDate === '') {
      this.snackBar.open(this.language.SELECT_PREORDER_DATE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    if (this.data.preorder.preorderTime === '') {
      this.snackBar.open(this.language.SELECT_PREORDER_TIME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    this.data.preorder.preorder = true;
    this.data.preorder.preorderBusiness = this.data.business.id;
    this.data.preorder.preorderDateString = this.data.preorder.preorderDate.toDateString();
    console.log(this.data.menu);

    for (let m of this.data.menu) {
      m.active = false;
    }
    this.dialogRef.close(this.data.preorder);
  }

  displayVal(id: any) {
    return this.comservice.displayVal(id);
  }

  changeMenu(menu: any) {
    for (let m of this.data.menu) {
      if (m.id === menu.id) {
        this.data.preorder.preorderMenu = m.id;
        this.dateFilter = (d: Date): boolean => {
          const dd = new Date(d);
          const day = (dd.getDay() === 0) ? 7 : dd.getDay();

          return (m.days.indexOf(day) !== -1);
        };
        this.dateClass = (d: Date) => {
          const dd = new Date(d);
          const day = (dd.getDay() === 0) ? 7 : dd.getDay();
          return (m.days.indexOf(day) === -1) ? (d.getDate() >= this.minDate.getDate() && d.getDate() <= this.maxDate.getDate()) ? 'example-custom-date-class' : undefined : undefined;
        };
        m.active = true;
      } else {
        m.active = false;
      }
    }
    this.data.preorder.preorderDate = '';
    this.data.preorder.preorderTime = '';
    try {
      console.log(this.myScrollContainer.nativeElement.scrollHeight)
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

}
