import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { CommonService } from './../common.service';
import { Globals } from './../globals';

declare var $: any;

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  public globals: any;
  public minDate = new Date();
  public maxDate = new Date();
  public businessDetails: any;
  public bookingTime: any;
  public personNumber: any;
  public postData: any;
  public userId: any = 0;
  public sourcetype: any;
  public sourcetypename: any;

  public language;
  public bookDetails: any = {
    bid: '',
    uid: -1,
    bookingDate: '',
    bookingDateString: '',
    bookingTime: '',
    name: '',
    email: '',
    cel: '',
    person: '',
    notes: ''
  };

  @ViewChild('picker') picker: ElementRef;
  @ViewChild('b_time') b_time: ElementRef;
  @ViewChild('b_name') b_name: ElementRef;
  @ViewChild('b_email') b_email: ElementRef;
  @ViewChild('b_cel') b_cel: ElementRef;
  @ViewChild('b_person') b_person: ElementRef;
  @ViewChild('pickerone') pickerone: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    public location: Location,
    public router: Router,
    private deviceService: DeviceDetectorService,
    private comservice: CommonService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
  }
  ngOnInit() {
    this.businessDetails = this.comservice.getService('businessDetails');
    let date;
    date = new Date().toLocaleString('en-US', {
      timeZone: this.businessDetails.code
    });
    this.minDate = new Date(date);
    this.maxDate.setDate(this.minDate.getDate() + this.businessDetails.bookingperiod - 1);

    this.personNumber = new Array();
    for (let i = 1; i <= this.businessDetails.bookingperson; i++) {
      this.personNumber.push(i);
    }
    let userDetails;
    userDetails = this.comservice.getFromLocal('user-details');
    if (userDetails === null || userDetails === undefined || userDetails === null) {
      this.bookDetails.name = '';
      this.bookDetails.email = '';
      this.bookDetails.cel = '';
      this.userId = -1;
    } else {
      let name;
      name = userDetails.name + ' ' + userDetails.last_name;
      this.bookDetails.name = name;
      this.bookDetails.email = userDetails.email;
      this.bookDetails.cel = userDetails.cel;
      this.userId = userDetails.id;
    }
    this.sourcetype = 'web';
    this.sourcetypename = this.deviceService.getDeviceInfo();
  }
  onDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    let d;
    d = new Date(event.value);
    let day;
    day = (d.getDay() === 0) ? 7 : d.getDay();
    let bookingSchedule;
    bookingSchedule = this.businessDetails.bookingschedule.sdays;
    let open;
    let close;
    open = bookingSchedule[day].opens.hour + ':' + bookingSchedule[day].opens.minute;
    close = bookingSchedule[day].closes.hour + ':' + bookingSchedule[day].closes.minute;


    const minutesToAdjust = this.businessDetails.bookinginterval;
    const millisecondsPerMinute = 60000;
    let now;
    let nowTime;
    let userTime1, userTime2;
    let date;
    date = new Date().toLocaleString('en-US', {
      timeZone: this.businessDetails.code
    });
    now = new Date(date);
    nowTime = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + now.getHours() + ':' + now.getMinutes());
    userTime1 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + open);
    userTime2 = new Date((now.getMonth() + 1) + '/' + now.getDate() + '/' + now.getFullYear() + ' ' + close);

    this.bookingTime = new Array();

    if (day === now.getDay()) {
      if (nowTime.getTime() < userTime1.getTime()) {
        let time;
        time = userTime1;
        while (time.getTime() <= userTime2.getTime()) {
          let hour;
          hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
          let minute;
          minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
          this.bookingTime.push(hour + ':' + minute);
          time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
        }
      } else if (nowTime.getTime() >= userTime1.getTime() && nowTime.getTime() <= userTime2.getTime()) {
        let time;
        time = nowTime;
        time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
        while (time.getTime() <= userTime2.getTime()) {
          let hour;
          hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
          let minute;
          minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
          this.bookingTime.push(hour + ':' + minute);
          time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
        }
      }
    } else {
      let time;
      time = userTime1;
      while (time.getTime() <= userTime2.getTime()) {
        let hour;
        hour = (time.getHours() <= 9) ? '0' + time.getHours() : '' + time.getHours();
        let minute;
        minute = (time.getMinutes() <= 9) ? '0' + time.getMinutes() : '' + time.getMinutes();
        this.bookingTime.push(hour + ':' + minute);
        time = new Date(time.valueOf() + (minutesToAdjust * millisecondsPerMinute));
      }
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  CheckallBookingDetails() {
    let atpos;
    atpos = this.bookDetails.email.indexOf('@');
    let dotpos;
    dotpos = this.bookDetails.email.lastIndexOf('.');

    if (this.bookDetails.bookingDate === '') {
      this.snackBar.open(this.language.SELECT_BOOKING_DATE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.pickerone.nativeElement.focus();
      }
      return false;
    } else if (this.bookDetails.bookTime === '') {
      this.snackBar.open(this.language.SELECT_BOOKING_TIME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_time.nativeElement.focus();
      }
      return false;
    } else if (this.bookDetails.name === '') {
      this.snackBar.open(this.language.ENTER_YOUR_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_name.nativeElement.focus();
      }
      return false;
    } else if (this.bookDetails.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_email.nativeElement.focus();
      }
      return false;
    }  else if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.bookDetails.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_email.nativeElement.focus();
      }
      return false;
    } else if (this.bookDetails.cel === '') {
      this.snackBar.open(this.language.ENTER_MOBILE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_cel.nativeElement.focus();
      }
      return false;
    } else if (this.bookDetails.person === '') {
      this.snackBar.open(this.language.SELECT_PERSON, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_person.nativeElement.focus();
      }
      return false;
    }
    let d;
    d = new Date(this.bookDetails.bookingDate);
    d = d.toLocaleDateString();
    this.bookDetails.bookingDateString = d;
    this.bookOrder(this.comservice, this.router);
  }
  bookOrder(comservice, router) {
    this.postData = {
      f: 'placebooking',
      langId: 1,
      userId: this.userId,
      bid: this.businessDetails.id,
      data: JSON.stringify(this.bookDetails),
      sourcetype: this.sourcetype,
      sourcetypename: JSON.stringify(this.sourcetypename),
    };
    $.post(Globals.API_ENDPOINT_PLACE, this.postData, function (res) {
      this.orderId = res.placebooking;
      comservice.setService('bookId', this.orderId);
      router.navigate(['/confirmation']);
    });
  }

  omit_special_char(event) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
