import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from '../globals';

export interface DialogData {
  cuisines: any;
}

@Component({
  selector: 'app-cuisine',
  templateUrl: './cuisine.component.html',
  styleUrls: ['./cuisine.component.css']
})
export class CuisineComponent implements OnInit {
  options = { autoHide: true, scrollbarMinSize: 100 };
  public globals: Globals;
  public isCuisineContent: any;
  public language: any;
  public searchText: any;
  public cuisineImage = './assets/images/restaurant.svg';

  constructor(
    public dialogRef: MatDialogRef < CuisineComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
    this.isCuisineContent = data.cuisines;
  }

  ngOnInit() {
  }
  onNoClick() {
    this.dialogRef.close();
  }

}
