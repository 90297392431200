import { WINDOW } from '@ng-toolkit/universal';
import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as SecureLS from 'secure-ls';

@Injectable()
export class CommonService {
  public data: any = [];
  public serviceList: any = [];
  private _ls = new SecureLS({});

  constructor(@Inject(WINDOW) private window: Window, @Inject(SESSION_STORAGE) private storage: StorageService) { }
  set(key: string, value: any) {
    this._ls.set(key, value);
  }

  remove(key: string) {
    this._ls.remove(key);
  }

  get(key: string) {
    return this._ls.get(key);
  }

  clear() {
    this._ls.removeAll();
  }

  scrollTop() {
    this.window.scrollTo(0, 0);
  }

  scrollDown() {
    this.window.scrollTo(5, 5);
  }
  setService(key: any, val: any): void {
    this.serviceList[key] = val;
  }
  getService(key: any): void {
    return this.serviceList[key];
  }
  deleteService(key: any): void {
    const index = this.serviceList.indexOf(key, 0);
    if (index > -1) {
      this.serviceList.splice(index, 1);
    }
  }

  saveInLocal(key: any, val: any): void {
    // console.log('recieved= key:' + key + ' value:' + val);
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  getFromLocal(key: any): void {
    // console.log('recieved= key:' + key);
    return this.data[key] = this.storage.get(key);
  }

  removeFromLocal(key: any): void {
    // console.log('recieved= key:' + key);
    this.storage.remove(key);
  }
  displayVal(val: any) {
    if (val === 0) {
      return 'Everyday'; // FRONTLANGUAGES.EVERYDAY;
    }
    if (val === 1) {
      return 'Monday'; // FRONTLANGUAGES.MONDAY;
    }
    if (val === 2) {
      return 'Tuesday'; // FRONTLANGUAGES.TUESDAY;
    }
    if (val === 3) {
      return 'Wednesday'; // FRONTLANGUAGES.WEDNESDAY;
    }
    if (val === 4) {
      return 'Thursday'; // FRONTLANGUAGES.THURSDAY;
    }
    if (val === 5) {
      return 'Friday'; // FRONTLANGUAGES.FRIDAY;
    }
    if (val === 6) {
      return 'Saturday'; // FRONTLANGUAGES.SATURDAY;
    }
    if (val === 7) {
      return 'Sunday'; // FRONTLANGUAGES.SUNDAY;
    }
  }
}

