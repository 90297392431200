import { Component, ComponentFactory, ComponentFactoryResolver, HostListener } from '@angular/core';
import { ElementRef, Inject, ViewChild } from '@angular/core';
import { NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { Location, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SocialAuthService } from 'angularx-social-login';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { ChangepasswordComponent } from './../changepassword/changepassword.component';
import { OrderComponent } from './../order/order.component';
import { FavComponent } from './../fav/fav.component';
import { DataSharingService } from './../data-share.service';
import { BookinglistComponent } from '../bookinglist/bookinglist.component';
import { RewardhistoryComponent } from '../rewardhistory/rewardhistory.component';
import { Globals } from '../globals';

// import {} from 'googlemaps'
/// <reference types="@types/googlemaps" />

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public globals: Globals;
  public tabChange: any;
  public userdetails: any;
  public userId: any;
  public searchflag: any;

  public language: any;
  public uploadResponse: any;
  public appType: any;

  @ViewChild('profile_name') profile_name!: ElementRef;
  @ViewChild('profile_lname') profile_lname!: ElementRef;
  @ViewChild('profile_email') profile_email!: ElementRef;
  @ViewChild('profile_cel') profile_cel!: ElementRef;


  @ViewChild('profileSubContainer1', { read: ViewContainerRef }) profileSubContainer1!: ViewContainerRef;
  @ViewChild('profileSubContainer2', { read: ViewContainerRef }) profileSubContainer2!: ViewContainerRef;
  @ViewChild('profileSubContainer3', { read: ViewContainerRef }) profileSubContainer3!: ViewContainerRef;
  @ViewChild('profileSubContainer4', { read: ViewContainerRef }) profileSubContainer4!: ViewContainerRef;
  @ViewChild('profileSubContainer5', { read: ViewContainerRef }) profileSubContainer5!: ViewContainerRef;
  @ViewChild('search') public searchElement!: ElementRef;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public snackBar: MatSnackBar,
    private authService: SocialAuthService,
    private spinner: NgxSpinnerService,
    private compFactoryResolver: ComponentFactoryResolver,
    public location: Location,
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    this.tabChange = 1;
    this.userId = this.comservice.getFromLocal('userId');
    if (this.userId > 0) {
      this.userdetails = this.comservice.getFromLocal('user-details');
    } else {
      this.router.navigate(['/']);
      return false;
    }
    this.spinner.show();
    this.apiservice.getUser(this.userId).subscribe((response: any) => {
      this.userdetails = response;
      if (this.userdetails.address === null || this.userdetails.address === 'null' || this.userdetails.address === undefined) {
        this.searchflag = 0;
      } else {
        this.searchflag = 1;
      }

      this.comservice.saveInLocal('user-details', this.userdetails);
      this.myProfile();
    });
  }
  onSelectFile(event: any) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData();
      formData.append('myFile', event.target.files[0], event.target.files[0].name);
      formData.append('myId', this.userdetails.id);
      this.apiservice.updateUserImage(formData).subscribe((response: any) => {
        this.uploadResponse = response;
        this.userdetails.img = this.uploadResponse.data.secure_url;
        this.comservice.saveInLocal('user-details', this.userdetails);
        this.snackBar.open(this.language.SUCCESSFULLY_UPDATED, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      });
    }
  }
  myProfile() {
    let compFactory: ComponentFactory<any>;
    compFactory = this.compFactoryResolver.resolveComponentFactory(ChangepasswordComponent);
    this.profileSubContainer1.createComponent(compFactory);
    compFactory = this.compFactoryResolver.resolveComponentFactory(FavComponent);
    this.profileSubContainer2.createComponent(compFactory);
    compFactory = this.compFactoryResolver.resolveComponentFactory(OrderComponent);
    this.profileSubContainer3.createComponent(compFactory);
    compFactory = this.compFactoryResolver.resolveComponentFactory(BookinglistComponent);
    this.profileSubContainer4.createComponent(compFactory);
    compFactory = this.compFactoryResolver.resolveComponentFactory(RewardhistoryComponent);
    this.profileSubContainer5.createComponent(compFactory);

    this.googelLoad();
  }
  googelLoad() {

    // set current position
    this.mapsAPILoader.load().then(
      () => {
        // types: [], componentRestrictions: { country: 'IN' }
        let autocomplete: any;
        autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {});

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place.geometry === undefined || place.geometry === null) {
              return;
            } else {
              if (this.userdetails.location == null) {
                this.userdetails.location = {
                  zip: '',
                  address: '',
                  lat: '',
                  lng:''
                }
              }
              for (let i = 0; i < place.address_components.length; i++) {
                for (let j = 0; j < place.address_components[i].types.length; j++) {
                  if (place.address_components[i].types[j] === 'postal_code') {
                    this.userdetails.location.zip = place.address_components[i].long_name;
                  }
                }
              }
              this.userdetails.location.address = place.formatted_address;
              this.userdetails.address = place.formatted_address;
              this.userdetails.location.lat = place.geometry.location.lat();
              this.userdetails.location.lng = place.geometry.location.lng();
              this.searchflag = 1;
            }
          });
        });
      }
    );
    this.spinner.hide();
  }
  changeTab(val: any) {
    this.tabChange = val;
    if (val === 1) {
      this.dataSharingService.isChangePassword.next(false);
      this.dataSharingService.isFavBusiness.next(false);
      this.dataSharingService.isOrderHistory.next(false);
      this.dataSharingService.isBookingHistory.next(false);
      this.dataSharingService.isRewardHistory.next(false);

    }
    if (val === 2) {
      this.dataSharingService.isChangePassword.next(true);
      this.dataSharingService.isFavBusiness.next(false);
      this.dataSharingService.isOrderHistory.next(false);
      this.dataSharingService.isBookingHistory.next(false);
      this.dataSharingService.isRewardHistory.next(false);
    }
    if (val === 3) {
      this.dataSharingService.isChangePassword.next(false);
      this.dataSharingService.isFavBusiness.next(true);
      this.dataSharingService.isOrderHistory.next(false);
      this.dataSharingService.isBookingHistory.next(false);
      this.dataSharingService.isRewardHistory.next(false);
    }
    if (val === 4) {
      this.dataSharingService.isChangePassword.next(false);
      this.dataSharingService.isFavBusiness.next(false);
      this.dataSharingService.isOrderHistory.next(true);
      this.dataSharingService.isBookingHistory.next(false);
      this.dataSharingService.isRewardHistory.next(false);
    }
    if (val === 5) {
      this.dataSharingService.isChangePassword.next(false);
      this.dataSharingService.isFavBusiness.next(false);
      this.dataSharingService.isOrderHistory.next(false);
      this.dataSharingService.isBookingHistory.next(true);
      this.dataSharingService.isRewardHistory.next(false);
    }
    if (val === 6) {
      this.dataSharingService.isChangePassword.next(false);
      this.dataSharingService.isFavBusiness.next(false);
      this.dataSharingService.isOrderHistory.next(false);
      this.dataSharingService.isBookingHistory.next(false);
      this.dataSharingService.isRewardHistory.next(true);
    }
  }
  updateUser() {
    if (this.userdetails.name === '') {
      this.snackBar.open(this.language.ENTER_FIRST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.profile_name.nativeElement.focus();
      }
      return false;
    }
    if (this.userdetails.last_name === '') {
      this.snackBar.open(this.language.ENTER_YOUR_LAST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.profile_lname.nativeElement.focus();
      }
      return false;
    }
    if (this.userdetails.address === '' || this.searchflag !== 1) {
      this.snackBar.open(this.language.ENTER_YOUR_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.searchElement.nativeElement.focus();
      }
      return false;
    }
    if (this.userdetails.email === '') {
      this.snackBar.open(this.language.ENTER_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.profile_email.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.userdetails.email.indexOf('@');
    let dotpos;
    dotpos = this.userdetails.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.userdetails.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.profile_email.nativeElement.focus();
      }
      return false;
    }
    if (this.userdetails.cel === '') {
      this.snackBar.open(this.language.ENTER_YOUR_MOBILE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.profile_cel.nativeElement.focus();
      }
      return false;
    }
    this.apiservice.getUpdateUser(this.userdetails).subscribe((response: any) => {
      this.userdetails = response;
      this.comservice.saveInLocal('user-details', this.userdetails);
      this.snackBar.open(this.language.SUCCESSFULLY_UPDATED, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    });
  }
  PlaceChanged() {
    this.searchflag = 0;
  }
  goToLogout() {
    this.comservice.deleteService('Search');
    this.comservice.deleteService('businessID');
    this.comservice.deleteService('businessDetails');
    this.comservice.deleteService('cartDetails');
    this.comservice.deleteService('categoryDetails');
    this.comservice.deleteService('myClonedcategoryDetails');
    this.comservice.deleteService('MenuDetails');
    this.comservice.deleteService('orderType');
    this.comservice.deleteService('deliveryDetails');
    this.comservice.removeFromLocal('businessID');
    this.comservice.removeFromLocal('user-details');
    this.comservice.removeFromLocal('userId');
    // this.authService.signOut();
    this.userdetails = {};
    if (!this.globals.APP_TYPE) {
      this.router.navigate(['/' + this.globals.BUSINESS_SLUG]).then(() => {
        location.reload();
      });
    } else {
      this.router.navigate(['/'])
      .then(() => {
        location.reload();
      });
    }
  }

  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
