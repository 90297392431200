import { WINDOW } from '@ng-toolkit/universal';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from './../api.service';
import { Globals } from '../globals';

export interface DialogData {
  business: any;
  successurl: any;
  errorurl: any;
  timezone: any;
  txndatetime: any;
  hash: any;
  storename: any;
  chargetotal: any;
  currency: any;
}

@Component({
  selector: 'app-firstdata',
  templateUrl: './firstdata.component.html',
  styleUrls: ['./firstdata.component.css']
})
export class FirstdataComponent {
  public globals: Globals;
  myInnerHeight: any;
  public timeout: any;
  public firstadata: any = { status: false, firstdata: '', ipgTransactionId: ''};
  public language: any;

  constructor(
    @Inject(WINDOW) private window: Window,
    public snackBar: MatSnackBar,
    private apiservice: ApiService,
    public dialogRef: MatDialogRef < FirstdataComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private spinner: NgxSpinnerService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
    this.myInnerHeight = window.innerHeight;
    setTimeout(() => {
      this.formSubmit();
    }, 100);
    this.timeout =  setInterval(() => {
      this.callFuntionAtIntervals();
    }, 3000);
  }
  callFuntionAtIntervals() {
    this.apiservice.getCheck().subscribe((response: any) => {
      if (response.status === true) {
        this.firstadata = { status: true, firstdata: response.firstdata, ipgTransactionId: response.ipgTransactionId};
        clearTimeout(this.timeout);
        this.onNoClick();
      }
    });
  }
  onNoClick() {
    this.dialogRef.close(this.firstadata);
  }
  formSubmit() {
    let myForm;
    myForm = <HTMLFormElement>document.getElementById('firstdataform');
    myForm.submit();
  }
}
