import { Component, Inject, OnInit } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { MatDialog } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { CommonService } from './../common.service'
import { ApiService } from './../api.service'
import { DataSharingService } from './../data-share.service'
import { BookingdetailsComponent } from '../bookingdetails/bookingdetails.component'
import { Globals } from '../globals'

@Component({
  selector: 'app-bookinglist',
  templateUrl: './bookinglist.component.html',
  styleUrls: ['./bookinglist.component.css']
})
export class BookinglistComponent implements OnInit {
  public globals: Globals;
  public isBookingHistory: boolean;
  public userId: any;
  public BookHistory: any = [];
  public language: any;
  public searchText: any = '';
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private comservice: CommonService,
    private apiservice: ApiService,
    private dataSharingService: DataSharingService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    this.dataSharingService.isBookingHistory.subscribe(value => {
      this.isBookingHistory = value;
      this.ngOnInit();
    });
  }
  ngOnInit() {
    if (this.isBookingHistory === true) {
      this.userId = this.comservice.getFromLocal('userId');
      this.apiservice.getBookingByUser((this.globals.APP_TYPE) ? 0 : this.globals.BUSINESS_ID, this.userId).subscribe(response => {
        this.BookHistory = response;
      });
    }
  }
  viewDetails(order) {
    const forgotdialogRef = this.dialog.open(BookingdetailsComponent, {
      width: '700px',
      data: {
        order: order
      },
      disableClose: true
    });

    forgotdialogRef.afterClosed().subscribe(result => {});
  }
}
