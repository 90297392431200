import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, HostListener } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { Globals } from '../globals';

declare var $: any;

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
  public globals: any = Globals;
  public businessReviews: any = [];
  public bDetails: any;
  public Quality: any = '';
  public Punctuality: any = '';
  public Service: any = '';
  public Packing: any = '';
  public username: any = '';
  public useremail: any = '';
  public usercomments: any = '';
  public reviewResponse: boolean = false;
  public language;
  public userdetails: any;
  public countReview: any;
  public loadMoreResponse = false;

  @ViewChild('rename') rename!: ElementRef;
  @ViewChild('reemail') reemail!: ElementRef;
  @ViewChild('remessage') remessage!: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    public comservice: CommonService,
    public apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    let userId: any;
    userId = this.comservice.getFromLocal('userId');
    if (parseInt(userId) > 0) {
      this.userdetails = this.comservice.getFromLocal('user-details');
      this.username = this.userdetails.name;
      this.useremail = this.userdetails.email;
    }

    this.bDetails = this.comservice.getService('businessDetails');
    this.apiservice.getReviewByBusiness(this.bDetails.id, 0).subscribe((response: any) => {
      this.businessReviews = response.data;
      this.countReview = response.count;
      this.reviewResponse = true;
    });
    $('#stars li').on('click', () => {
      let onStar;
      onStar = parseInt($(this).data('value'), 10); // The star currently selected
      let stars;
      stars = $(this).parent().children('li.star');

      for (let i = 0; i < stars.length; i++) {
        $(stars[i]).removeClass('selected');
      }

      for (let i = 0; i < onStar; i++) {
        $(stars[i]).addClass('selected');
      }
    });
  }
  loadMore() {
    this.loadMoreResponse = true;
    this.apiservice.getReviewByBusiness(this.bDetails.id, this.businessReviews.length).subscribe((response: any) => {
      for (let i of response.data) {
        this.businessReviews.push(i);
      }
      this.countReview = response.count;
      this.loadMoreResponse = false;
    });
  }

  reviewStar(type: any, val: any) {
    if (type === 1) {
      this.Quality = val;
    }
    if (type === 2) {
      this.Punctuality = val;
    }
    if (type === 3) {
      this.Service = val;
    }
    if (type === 4) {
      this.Packing = val;
    }
  }
  validateEmail(email: any) {
    let re;
    re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  addReview(): any {
    if (this.Quality === '') {
      this.snackBar.open(this.language.RATE_QUALITY, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    if (this.Punctuality === '') {
      this.snackBar.open(this.language.RATE_PUNCTUALITY, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    if (this.Service === '') {
      this.snackBar.open(this.language.RATE_SERVICE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    if (this.Packing === '') {
      this.snackBar.open(this.language.RATE_FOOD, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }
    if (this.username === '') {
      this.snackBar.open(this.language.ENTER_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.rename.nativeElement.focus();
      }
      return false;
    }
    if (this.useremail === '') {
      this.snackBar.open(this.language.ENTER_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.reemail.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.useremail.indexOf('@');
    let dotpos;
    dotpos = this.useremail.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.useremail.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.reemail.nativeElement.focus();
      }
      return false;
    }
    if (this.usercomments === '') {
      this.snackBar.open(this.language.ENETER_COMMENTS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.remessage.nativeElement.focus();
      }
      return false;
    }
    this.apiservice.setReviewByBusiness(this.bDetails.id, this.Quality, this.Punctuality, this.Service, this.Packing, this.username, this.useremail, this.usercomments).subscribe((response: any) => {

      if (response === true) {
        this.snackBar.open(this.language.THANKS_FOR_REVIEWS, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        $('.Quality').find('li').each(() => {
          let stars;
          stars = $(this).parent().children('li.star');
          for (let i = 0; i < stars.length; i++) {
            $(stars[i]).removeClass('selected');
          }
        });
        $('.Punctuality').find('li').each(() => {
          let stars;
          stars = $(this).parent().children('li.star');
          for (let i = 0; i < stars.length; i++) {
            $(stars[i]).removeClass('selected');
          }
        });
        $('.Service').find('li').each(() => {
          let stars;
          stars = $(this).parent().children('li.star');
          for (let i = 0; i < stars.length; i++) {
            $(stars[i]).removeClass('selected');
          }
        });
        $('.packing').find('li').each(() => {
          let stars;
          stars = $(this).parent().children('li.star');
          for (let i = 0; i < stars.length; i++) {
            $(stars[i]).removeClass('selected');
          }
        });
        this.username = '';
        this.useremail = '';
        this.usercomments = '';
      }
    });
  }
  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
