import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { CommonService } from '../common.service';
import { Globals } from '../globals';

declare var $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  public globals: Globals;
  public language: any;
  public txnId: any;
  public discountInfo: any;
  public rewardsData: any;
  public buyerDetails: any;
  public CartDish: any;
  public orderType: any;
  public Search: any;
  public preorderDetails: any;
  public deliveryDetails: any;
  public categoryDetails: any;
  public myClonedcategoryDetails: any;
  public MenuDetails: any;
  public businessDetails: any;
  public cartFee: any;
  public paymentMethod: any;
  public sourcetype: any;
  public sourcetypename: any;
  public extraJson: any = {};
  public postData: any;
  constructor(
    public router: Router,
    private comservice: CommonService,
    private apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    this.txnId = '';
    this.discountInfo = new Object();
    this.discountInfo = {
      'discounttext': '',
      'discounttype': '',
      'discountid': ''
    };
    this.rewardsData = new Object();
    this.buyerDetails = new Object();

    this.CartDish = this.comservice.get('cartDetails');
    this.orderType = this.comservice.get('orderType');
    this.Search = this.comservice.get('Search');
    this.preorderDetails = this.comservice.get('preorderDetails');
    this.deliveryDetails = this.comservice.get('deliveryDetails');

    let stripeRedirect;
    stripeRedirect = this.comservice.get('stripeRedirect');

    this.categoryDetails = this.comservice.get('categoryDetails');
    this.myClonedcategoryDetails = this.comservice.get('myClonedcategoryDetails');
    this.MenuDetails = this.comservice.get('MenuDetails');
    this.buyerDetails = this.comservice.get('buyerDetails');
    this.businessDetails = this.comservice.get('businessDetails');
    this.cartFee = this.comservice.get('cartFee');
    this.paymentMethod = this.comservice.get('paymentMethod');
    this.rewardsData = this.comservice.get('rewardWallet');
    this.sourcetype = this.comservice.get('sourcetype');
    this.sourcetypename = this.comservice.get('sourcetypename');
    this.discountInfo = this.comservice.get('discountInfo');
    this.extraJson = this.comservice.get('extraJson');


    this.comservice.remove('businessDetails');
    this.comservice.remove('categoryDetails');
    this.comservice.remove('myClonedcategoryDetails');
    this.comservice.remove('MenuDetails');
    this.comservice.remove('buyerDetails');
    this.comservice.remove('stripeRedirect');

    this.comservice.remove('cartFee');
    this.comservice.remove('paymentMethod');
    this.comservice.remove('rewardWallet');
    this.comservice.remove('sourcetype');
    this.comservice.remove('sourcetypename');
    this.comservice.remove('discountInfo');
    this.comservice.remove('extraJson');


    console.log(stripeRedirect);

    this.txnId = stripeRedirect.payment_intent;
    this.PlaceOrder(this.comservice, this.router, this);

  }
  PlaceOrder(comservice: any, router: any, event: any) {
    this.buyerDetails.name = this.buyerDetails.name.replace(/'/g, "");
    this.buyerDetails.name = this.buyerDetails.name.replace(/\"/g, "");

    this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/'/g, "");
    this.buyerDetails.last_name = this.buyerDetails.last_name.replace(/\"/g, "");

    this.buyerDetails.notes = this.buyerDetails.notes.replace(/'/g, "");
    this.buyerDetails.notes = this.buyerDetails.notes.replace(/\"/g, "");


    this.postData = {
      f: 'placeorder',
      langId: 1,
      userId: this.buyerDetails.id,
      CartDish: JSON.stringify(this.CartDish),
      cartFee: JSON.stringify(this.cartFee),
      BuyerDetails: JSON.stringify(this.buyerDetails),
      deliveryDetails: JSON.stringify(this.deliveryDetails),
      Search: JSON.stringify(this.Search),
      paymentMethod: JSON.stringify(this.paymentMethod),
      rewardWallet: JSON.stringify(this.rewardsData),
      sourcetype: this.sourcetype,
      sourcetypename: JSON.stringify(this.sourcetypename),
      discountInfo: JSON.stringify(this.discountInfo),
      txnId: this.txnId,
      extraJson: JSON.stringify(this.extraJson)
    };

    $.post(Globals.API_ENDPOINT_PLACE, this.postData, (res: any) => {
      event.orderId = res.placeorder[0];
      comservice.setService('orderId', event.orderId);
      router.navigate(['/confirmation']);
    });
  }

}
