import { Component, OnInit } from '@angular/core';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Globals } from '../globals';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  public globals: Globals;
  public businessOffers: any = [];
  public bDetails: any;
  public language: any;
  public offerResponse: boolean = false;
  public foodImage = './assets/images/food1.jpeg';

  constructor(
    public comservice: CommonService,
    public apiservice: ApiService,
    private spinner: NgxSpinnerService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    this.bDetails = this.comservice.getService('businessDetails');
    this.apiservice.getOfferByBusiness(this.bDetails.id).subscribe((response: any) => {
      this.businessOffers = response;
      this.offerResponse = true;
    });
  }
}
