
import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { Globals } from '../globals';
import { AlertComponent } from '../alert/alert.component';

// import {} from 'googlemaps'
/// <reference types="@types/googlemaps" />

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public globals: Globals;
  options = { autoHide: true, scrollbarMinSize: 100 };
  public allcountry: any = [];
  public cityByCntry: any = [];

  public ulocation: any = {
    address: '',
    lat: '',
    lng: '',
    zip: ''
  };
  public bulocation: any = {
    latitud: '',
    longitud: '',
    zipcode: '',
    zoom: 16
  };
  public language: any;
  public reguser: any = {
    firstname: '',
    lastname: '',
    email: '',
    cel: '',
    bname: '',
    country: '',
    city: '',
    address: '',
    location: '',
    blocation: ''
  };
  public addressFlag = 0;

  @ViewChild('first_name') public first_name!: ElementRef;
  @ViewChild('last_name') public last_name!: ElementRef;
  @ViewChild('r_email') public r_email!: ElementRef;
  @ViewChild('r_cel') public r_cel!: ElementRef;
  @ViewChild('b_name') public b_name!: ElementRef;
  @ViewChild('b_country') public b_country!: ElementRef;
  @ViewChild('b_city') public b_city!: ElementRef;
  @ViewChild('search_a') public searchElement!: ElementRef;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef < RegisterComponent >,
    private spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object,
    private apiservice: ApiService,
    private comservice: CommonService,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    this.comservice.scrollTop();
    this.apiservice.getAllCountry().subscribe((response: any) => this.allcountry = response);

    this.ulocation = {
      address: '',
      lat: '',
      lng: '',
      zip: ''
    };

    this.bulocation = {
      latitud: '',
      longitud: '',
      zipcode: '',
      zoom: 16
    };


    // set current position
    this.mapsAPILoader.load().then(
      () => {
        // types: [], componentRestrictions: { country: 'IN' }
        let autocomplete: any;
        autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {});

        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            if (place?.geometry === undefined || place?.geometry === null) {
              return;
            } else {
              for (let i = 0; i < place?.address_components?.length; i++) {
                for (let j = 0; j < place?.address_components[i]?.types.length; j++) {
                  if (place?.address_components[i]?.types[j] === 'postal_code') {
                    this.ulocation.zip = place?.address_components[i]?.long_name;
                    this.bulocation.zipcode = place?.address_components[i]?.long_name;
                  }
                  let placeaddress: any;
                  placeaddress = place?.address_components[i]?.types[j];

                  if (placeaddress == 'administrative_area_level_1') {
                      // this is the object you are looking for
                      this.bulocation.city = place?.address_components[i]?.long_name ?? '';
                      break;
                  }
                }
              }
              this.ulocation.address = place.formatted_address;
              this.ulocation.lat = place.geometry.location.lat();
              this.ulocation.lng = place.geometry.location.lng();

              this.reguser.address = place.formatted_address;
              this.bulocation.latitud = place.geometry.location.lat();
              this.bulocation.longitud = place.geometry.location.lng();
              this.addressFlag = 1;
            }
          });
        });
      }
    );
  }

  onCountryChange(event: any) {
    event.target.value
    let val: any = (event.target as HTMLInputElement).value
    this.apiservice.getCityByCountry(val).subscribe((response: any) => this.cityByCntry = response);
  }

  onBusinessRegister(): any {
    if (this.reguser.firstname === '' || this.reguser.firstname.trim() === '') {
      this.snackBar.open(this.language.ENTER_FIRST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.first_name.nativeElement.focus();
      }
      return false;
    }

    if (this.reguser.lastname === '' || this.reguser.lastname.trim() === '') {
      this.snackBar.open(this.language.ENTER_YOUR_LAST_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.last_name.nativeElement.focus();
      }
      return false;
    }

    if (this.reguser.email === '' || this.reguser.email.trim() === '') {
      this.snackBar.open(this.language.ENTER_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.r_email.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.reguser.email.indexOf('@');
    let dotpos;
    dotpos = this.reguser.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.reguser.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.r_email.nativeElement.focus();
      }
      return false;
    }

    if (this.reguser.cel === '' || this.reguser.cel.trim() === '') {
      this.snackBar.open(this.language.ENTER_YOUR_MOBILE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.r_cel.nativeElement.focus();
      }
      return false;
    }
    if (this.reguser.cel.length < 10) {
      this.snackBar.open(this.language.ENTER_YOUR_MOBILE_MIN_DIGIT, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.r_cel.nativeElement.focus();
      }
      return false;
    }

    if (this.reguser.bname === '' || this.reguser.bname.trim() === '') {
      this.snackBar.open(this.language.ENTER_BUSINESS_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_name.nativeElement.focus();
      }
      return false;
    }

    if (this.reguser.country === '') {
      this.snackBar.open(this.language.PLEASE_SELECT_COUNTRY, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.b_country.nativeElement.focus();
      }
      return false;
    }
    if (this.addressFlag === 0) {
      this.snackBar.open(this.language.ENTER_CORRECT_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return false;
    }

    if (this.reguser.address === '') {
      this.snackBar.open(this.language.ENTER_YOUR_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.searchElement.nativeElement.focus();
      }
      return false;
    }
    if (this.reguser.city === '') {
      this.reguser.city = '0';
    }

    this.reguser.location = this.ulocation;
    this.reguser.blocation = this.bulocation;

    this.spinner.show();
    this.apiservice.setBusinessOwner(this.reguser).subscribe((response: any) => {
      this.spinner.hide();
      if (response.status) {
        this.onNoClick();
        this.dialog.open(AlertComponent, {
          width: '500px',
          data: {
            title: this.language.CONFIRMATION,
            note: this.language.REGISTER_DONE_BUSINESS_OWNER,
            ok: this.language.BUTTON_OK,
            cancel: ''
          },
          disableClose: true
        });
      } else {
        this.snackBar.open(response.message, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        return false;
      }
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }
  placeChanged() {
    this.addressFlag = 0;
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}

