import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { Globals } from '../globals';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  public globals: Globals;
  public businessDetails: any;
  public businessDays: any;
  public businessDaysObject: any;
  public contactDetails: any;
  panelOpenState: any = false;
  public language: any;

  @ViewChild('cuname') cuname!: ElementRef;
  @ViewChild('cuemail') cuemail!: ElementRef;
  @ViewChild('cumessage') cumessage!: ElementRef;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public comservice: CommonService,
    public snackBar: MatSnackBar,
    public apiservice: ApiService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    this.contactDetails = new Object();
    this.contactDetails = {
      name: '',
      email: '',
      message: ''
    };
    this.businessDetails = this.comservice.getService('businessDetails');
    this.businessDaysObject = this.businessDetails.schedule.sdays;
    this.businessDays = new Array();
    for (let [key, value] of Object.entries(this.businessDaysObject)) {
      this.businessDaysObject[key].time1 = true;
      this.businessDaysObject[key].time2 = true;
      this.businessDaysObject[key].dayname = this.comservice.displayVal(Number(key));
      if (this.businessDaysObject[key].opens.hour === '00' && this.businessDaysObject[key].opens.minute === '00' && this.businessDaysObject[key].closes.hour === '00' && this.businessDaysObject[key].closes.minute === '00') {
        this.businessDaysObject[key].time1 = false;
      }
      if (this.businessDaysObject[key].opens2.hour === '00' && this.businessDaysObject[key].opens2.minute === '00' && this.businessDaysObject[key].closes2.hour === '00' && this.businessDaysObject[key].closes2.minute === '00') {
        this.businessDaysObject[key].time2 = false;
      }

      this.businessDays.push(value);
    }
  }
  contactUs() {
    if (this.contactDetails.name === '') {
      this.snackBar.open(this.language.ENTER_YOUR_NAME, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.cuname.nativeElement.focus();
      }
      return false;
    }
    if (this.contactDetails.email === '') {
      this.snackBar.open(this.language.ENTER_YOUR_EMAIL, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.cuemail.nativeElement.focus();
      }
      return false;
    }
    let atpos;
    atpos = this.contactDetails.email.indexOf('@');
    let dotpos;
    dotpos = this.contactDetails.email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= this.contactDetails.email.length) {
      this.snackBar.open(this.language.ENTER_VALID_EMAIL_ADDRESS, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.cuemail.nativeElement.focus();
      }
      return false;
    }
    if (this.contactDetails.message === '') {
      this.snackBar.open(this.language.ENTER_YOUR_MESSAGE, this.language.TOAST_OK, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      if (isPlatformBrowser(this.platformId)) {
        this.cumessage.nativeElement.focus();
      }
      return false;
    }
    this.apiservice.setContact(this.contactDetails, this.businessDetails.id).subscribe((res: any) => {
      if (res.status) {
        this.snackBar.open(this.language.THANK_YOU_FOR_CONTACT_US, this.language.TOAST_OK, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.contactDetails = new Object();
        this.contactDetails = {
          name: '',
          email: '',
          message: ''
        };
      }
    });
  }

  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return(k !== 39 && k !== 34 && k !== 92 && k !== 13);
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
