import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from '../globals';

export interface DialogData {
  businessDetails: any;
  subtotal: any;
}

@Component({
  selector: 'app-requiredminimum',
  templateUrl: './requiredminimum.component.html',
  styleUrls: ['./requiredminimum.component.css']
})
export class RequiredminimumComponent {
  public globals: Globals;
  public language: any;
  public businessDetails: any;
  public subtotal: any;
  public chargedAmount: any;
  public minimumPrice: any;

  constructor(
    public dialogRef: MatDialogRef < RequiredminimumComponent >,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];

    this.businessDetails = this.data.businessDetails;
    this.minimumPrice = parseFloat(this.data.businessDetails.minimumfee).toFixed(2);
    this.subtotal = parseFloat(this.data.subtotal).toFixed(2);
    this.chargedAmount = parseFloat(this.data.businessDetails.minimumfee) - parseFloat(this.subtotal);
    this.chargedAmount = parseFloat(this.chargedAmount).toFixed(2);
  }

  onNoClick() {
    let response;
    response = {
      status: false
    };
    this.dialogRef.close(response);
  }

  addToExtraCharged() {
    let response;
    response = {
      status: true
    };
    this.dialogRef.close(response);
  }
}
