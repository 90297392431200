import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common.service';
import { DataSharingService } from '../data-share.service';
import { DishComponent } from '../dish/dish.component';
import { Globals } from '../globals';

export interface DialogData {
  productList: any;
  businessDetails: any;
  CartDish: any;
  freeItem: any;
}

@Component({
  selector: 'app-buyget',
  templateUrl: './buyget.component.html',
  styleUrls: ['./buyget.component.css']
})
export class BuygetComponent {

  public globals: Globals;
  options = { autoHide: true, scrollbarMinSize: 100 };
  public productList: any = [];
  public language;
  public businessDetails: any;
  public CartDish: any;
  public dish: any;
  public dishTotal: any;
  public orderType: any;
  public vegImage = './assets/images/veg.png';
  public nonvegImage = './assets/images/non-veg.png';
  public freeItem: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private comservice: CommonService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef < BuygetComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private dataSharingService: DataSharingService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals.LANG_ID];
    //this.productList = data.productList;
    this.businessDetails = data.businessDetails;
    this.CartDish = data.CartDish;
    this.freeItem = data.freeItem;
    this.orderType = this.comservice.get('orderType');

    for(const availDish of data.productList) {
      console.log(this.freeItem.includes(availDish.id))
      if (this.freeItem.includes(availDish.id)) {
        this.productList.push(availDish);
      }
    }

    this.dataSharingService.isBuyGetCart.subscribe(value => {
      if (value === true) {
        this.onNoClick();
        this.dataSharingService.isBuyGetCart.next(false);
      }
    });
  }
  openProduct(dish) {
    if (dish.sets !== undefined) {
      for (const sets of dish.sets) {
        if (sets.options.length > 0) {
          for (const option of sets.options) {
            if (option.choice.length > 0) {
              for (const choice of option.choice) {
                choice.checked = false;
              }
            }
          }
        }
      }
    }

    if (dish.sets.length > 0 || dish.ingredientsarray.length > 0) {
      const dishdialogRef = this.dialog.open(DishComponent, {
        width: '700px',
        data: {
          dish: dish,
          business: this.businessDetails,
          CartDish: (this.comservice.get('cartDetails') === '') ? new Array() : this.comservice.get('cartDetails'),
          type: 2
        },
        disableClose: true
      });

      dishdialogRef.afterClosed().subscribe(result => {
        if (result.status === true) {
          this.CartDish = result.cartDish;
          this.comservice.set('cartDetails', this.CartDish);
          this.dataSharingService.isCartAdded.next(true);
        }
      });
    } else {
      this.dish = dish;
      this.dishTotal = this.dish.price;
      this.addToCart();
    }
  }
  addToCart() {
    let bDeatilsCloned;
    bDeatilsCloned = JSON.stringify(this.businessDetails);
    let bDeatils;
    bDeatils = JSON.parse(bDeatilsCloned);
    bDeatils.street = JSON.parse(bDeatils.street);
    bDeatils.type = JSON.parse(bDeatils.type);
    bDeatils.location = JSON.parse(bDeatils.location);
    bDeatils.is_img = JSON.parse(bDeatils.is_img);
    bDeatils.is_banner = JSON.parse(bDeatils.is_banner);
    bDeatils.cuisine = JSON.parse(bDeatils.cuisine);

    let business;
    business = {
      id: this.businessDetails.id,
      details: bDeatils,
      dish: new Array()
    };

    let dish;
    dish = {
      'id': this.dish.id,
      'category': this.dish.category,
      'quantity': 1,
      'data' : new Array()
    };

    let activeIngredients;
    activeIngredients = new Array();

    let data;
    data = {
      'id': this.dish.id,
      'name': this.dish.name,
      'price': this.dish.price,
      'delprice': this.dish.delprice,
      'pickprice': this.dish.pickprice,
      'notes': this.dish.notes,
      'totalprice': this.dishTotal,
      'total': this.dishTotal,
      'is_img': this.dish.is_img,
      'img': this.dish.img,
      'spicy': this.dish.spicy,
      'spicyquantity': this.dish.spicyquantity,
      'veg': this.dish.veg,
      'nonveg': this.dish.nonveg,
      'ingredients': this.dish.ingredients,
      'ingredientsarray': this.dish.ingredientsarray,
      'activeIngredients': activeIngredients,
      'quantity': 1,
      'sets': new Array(),
      'relation': {
        'options': new Array(),
        'choices': new Array()
      },
      'free': {}
    };

    let parent_index = this.CheckBusinessAddtoCart(this.businessDetails.id);
    let dish_index = this.CartDish[parent_index].dish.length - 1;

    if (parseFloat(this.CartDish[parent_index].dish[dish_index].data[0].totalprice) > parseFloat(data.totalprice)) {
      data.totalprice = 0.00;
      data.total = 0.00;   
      data.totalprice = parseFloat(data.totalprice);
      data.total = parseFloat(data.total);
      dish.data.push(data);
      this.CartDish[parent_index].dish[dish_index].data[0].free = dish;
    } else {
      dish.data.push(data);
      let backupProduct;
      backupProduct = this.CartDish[parent_index].dish[dish_index];
      this.CartDish[parent_index].dish[dish_index] = dish;
      backupProduct.data[0].totalprice = 0.00;
      backupProduct.data[0].total = 0.00;
      backupProduct.data[0].totalprice = parseFloat(backupProduct.data[0].totalprice);
      backupProduct.data[0].total = parseFloat(backupProduct.data[0].total);

      this.CartDish[parent_index].dish[dish_index].data[0].free = backupProduct;
    }
    this.onNoClick();
    console.log(this.CartDish)
    
    if (this.CartDish.length > 0) {
      this.comservice.set('cartDetails', this.CartDish);
      this.dataSharingService.isCartAdded.next(true);
    }
  }
  CheckBusinessAddtoCart(d) {
    let b;
    b = this.CartDish;
    if (b === undefined) {
      return -1;
    }
    for (let c = 0; c < b.length; c++) {
      if (b[c].id === d) {
        return c;
      }
    }
    return -1;
  }
  
  onNoClick() {
    let response;
    response = {
      status: false
    };
    this.dialogRef.close(response);
  }

}
