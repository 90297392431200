import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from '../globals';

export interface DialogData {
  city: any;
}

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit {
  options = { autoHide: true, scrollbarMinSize: 100 };
  public globals: Globals;
  public isCityContent: any;
  public language: any;
  public searchText: any;
  public cityImage = './assets/images/target.svg';

  constructor(
    public dialogRef: MatDialogRef < CityComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
    this.isCityContent = data.city;
  }

  ngOnInit() {
  }
  onNoClick() {
    this.dialogRef.close();
  }

}
