<div class="new-pop">
  <h1>{{language.WHERE_ARE_YOU}}</h1>
  <a href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
    <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
    </svg>
  </a>

  <div class="where-bdy">
    <div class="row" *ngIf="businessDetails.search_type == '1'">
      <div class="col-md-12">
        <div class="google-combo-input-map">
          <input placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
            class="goolge-input form-control" #search [(ngModel)]="searchaddress">
          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [disableDefaultUI]="false"
            [zoomControl]="true" (mapClick)="mapLocation($event)">
            <agm-marker [latitude]="latitude " [longitude]="longitude" (markerClick)="clickedMarker(label)"
              [markerDraggable]="draggable" (dragEnd)="markerDragEnd($event)">
              <agm-info-window>
                <strong>{{label}}</strong>
              </agm-info-window>
            </agm-marker>
            <agm-polygon *ngFor="let path of paths" [strokeColor]="color" [strokeOpacity]="0.8" [fillColor]="color"
              [fillOpacity]="0.35" [strokeWeight]="2" [paths]="path" [clickable]="false">
            </agm-polygon>
          </agm-map>
        </div>
      </div>
      <div *ngIf="zonematch == false" class="col-md-12">
        <p class="sry-txt">
          <span><i class="fa fa-frown-o" aria-hidden="true"></i>
          </span>{{language.YOUR_AREA_IS_NOT_IN_DELIVERY}}
        </p>
      </div>
    </div>
    <div class="row" *ngIf="businessDetails.search_type == '2'">
      <div class="col-md-12">
        <div class="md-pdn1">
          <select [(ngModel)]="zipval">
            <option *ngFor="let opt of businessDetails.ziplist" value="{{opt.id}}">{{opt.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="businessDetails.search_type == '3'">
      <div class="col-md-12">
        <div class="md-pdn1">
          <select [(ngModel)]="zipval">
            <option *ngFor="let opt of businessDetails.citylist" value="{{opt.id}}">{{opt.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="businessDetails.search_type == '4'">
      <div class="col-md-12">
        <div class="md-pdn1">
          <select [(ngModel)]="zipval">
            <option *ngFor="let opt of businessDetails.arealist" value="{{opt.id}}">{{opt.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="flx-btn">
    <button class="sbm-btn cmn-button" (click)="pickupSearch()" cdkFocusInitial>{{language.CHANGE_WITH_PICKUP}}</button>
    &nbsp; &nbsp;
    <button (click)="checkWhereAreU()" class="sbm-btn cmn-button cncl-btn" cdkFocusInitial>{{language.SUBMIT}}</button>
  </div>
</div>
