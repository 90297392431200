import { Component, OnInit, OnDestroy, ViewRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common'
import { CommonService } from './../common.service';
import { ApiService } from './../api.service';
import { DataSharingService } from '../data-share.service';
import { Globals } from '../globals';
import { MapsAPILoader } from '@agm/core';

// import {} from 'googlemaps'
/// <reference types="@types/googlemaps" />

declare var google: any;

@Component({
  selector: 'app-confiramtion',
  templateUrl: './confiramtion.component.html',
  styleUrls: ['./confiramtion.component.css']
})
export class ConfiramtionComponent implements OnInit, OnDestroy {
  public globals: Globals;
  public route: any;
  public orderId: any;
  public bookId: any;
  public order: any;
  public orderview = false;
  public bookreview = false;
  public language: any;
  public origin: any;
  public destination: any;

  constructor(
    public router: Router,
    public location: Location,
    private spinner: NgxSpinnerService,
    private comservice: CommonService,
    private apiservice: ApiService,
    private cdRef: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private dataSharingService: DataSharingService,
    globals: Globals
  ) {
    this.globals = globals;
    this.language = this.globals.LANGUAGE[this.globals?.LANG_ID as keyof typeof this.globals.LANGUAGE];
  }

  ngOnInit() {
    this.comservice.scrollTop();
    this.order = new Array();
    this.comservice.setService('prevroute', 'confirmation');
    this.orderId = this.comservice.getService('orderId');
    this.bookId = this.comservice.getService('bookId');
    this.clearStorage();
    if (this.orderId === undefined && this.bookId === undefined) {
      this.router.navigate(['/']);
    }
    if (this.orderId !== undefined) {
      this.apiservice.getOrderById(this.orderId).subscribe((response: any) => {
        this.order = response;
        this.orderview = true;
        if (this.order.order_type === '1') {
          this.destination = {lat : this.order.blocation.latitud, lng : this.order.blocation.longitud};

          let event: any;
          event = this;
          this.mapsAPILoader.load().then(() => {
            let geocoder;
            geocoder = new google.maps.Geocoder();
            geocoder.geocode({'address': this.order.buyerdata.specialaddress }, (results: any, status: any) => {
              if (status === google.maps.GeocoderStatus.OK) {
                event.origin = {lat : results[0].geometry.location.lat(), lng : results[0].geometry.location.lng()};
              }
            });
          });
        }
      });
    }
    if (this.bookId !== undefined) {
      this.apiservice.getBookById(this.bookId).subscribe((response: any) => {
        this.order = response;
        this.bookreview = true;
      });
    }
  }
  clearStorage() {
    this.comservice.clear();
    setTimeout(() => {
      if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
        this.dataSharingService.isCartAdded.next(true);
        this.cdRef.detectChanges();
      }
    });
    this.comservice.deleteService('businessID');
    this.comservice.deleteService('businessDetails');
    this.comservice.deleteService('categoryDetails');
    this.comservice.deleteService('myClonedcategoryDetails');
    this.comservice.deleteService('MenuDetails');
    this.comservice.removeFromLocal('businessID');
  }
  ngOnDestroy() {
    this.router.navigate(['/']);
    location.reload();
  }
}
