import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from './globals';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private headers: Headers = new Headers({'Content-Type' : 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true'
  });
  public globals: Globals;

  constructor(
    private http: HttpClient,
    globals: Globals
  ) {
    this.globals = globals;
  }
  ipInfo() {
    const parameters = { };
    return this.http.get('https://ipinfo.io', { params: parameters }).pipe(map((res: any) => {
      return res;
    }));
  }

  getLogin(email, pass) {
    const parameters = { f: 'login', email: email, pass: pass, langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.login;
    }));
  }

  getLoginCheck(id) {
    const parameters = { id: id };
    return this.http.get(Globals.API_ENDPOINT_LOGIN_CHECK, { params: parameters }).pipe(map((res: any) => {
      return res;
    }));
  }

  setSignUp(user) {
    const parameters = { f: 'register', langId: this.globals.LANG_ID, name: user.name, last_name: user.last_name, email: user.email, pass: user.pass  };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.register;
    }));
  }
  getForgot(forgot) {
    const parameters = { f: 'forgot', langId: this.globals.LANG_ID, email: forgot.email};
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.forgot;
    }));
  }

  getBusinessByAddress(argument, lastid, uid, lang) {
    const parameters = { f: 'searchbyaddress', data: JSON.stringify(argument), lastid: lastid, uid: uid, langId: lang };
    return this.http.get(Globals.API_ENDPOINT_SEARCH, {params: parameters }).pipe(map((res: any) => {
      return res.searchbyaddress;
    }));
  }

  getAllPaymentMethod(bid) {
    const parameters = { f: 'checkpayment', langId: this.globals.LANG_ID, bid: bid };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.checkpayment;
    }));
  }

  getRewardsbyBusinessId(userID, bid) {
    const parameters = { f: 'rewardsbyid', usrid: userID, businessId: bid };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.rewardsbyid;
    }));
  }

  getOfferCheck(bid, price, orderType) {
    const parameters = { f: 'checkoffer', bid: bid, price: price, orderType: orderType };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.checkoffer;
    }));
  }

  getDiscountCheck(bid, total, code) {
    const parameters = { f: 'checkdiscount', bid: bid, total: total, code: code };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.checkdiscount;
    }));
  }

  getAllCms() {
    const parameters = { f: 'fetchall', langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_FOOTER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchall;
    }));
  }

  getAllCity(lang) {
    const parameters = { f: 'fetchallcity', langId: lang };
    return this.http.get(Globals.API_ENDPOINT_COMMON, { params: parameters }).pipe(map((res: any) => {
      return res.fetchallcity;
    }));
  }

  getAllCuisine(lang) {
    const parameters = { f: 'fetchallcuisine', langId: lang };
    return this.http.get(Globals.API_ENDPOINT_COMMON, { params: parameters }).pipe(map((res: any) => {
      return res.fetchallcuisine;
    }));
  }

  getCmsContent(pathstr) {
    const parameters = { f: 'fetchbycustomurl', langId: this.globals.LANG_ID, customurl: pathstr };

    return this.http.get(Globals.API_ENDPOINT_FOOTER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchbycustomurl;
    }));
  }

  getBusiness(pathstr, uid) {
    const parameters = { f: 'getbusinessid', customurl: pathstr, langId: this.globals.LANG_ID, uid: uid };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.getbusinessid;
    }));
  }
  setAddToFav(userId, bId) {
    const parameters = { f: 'addtofav', userId: userId, bId: bId };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.addtofav;
    }));
  }

  getProducts(id) {
    const parameters = { f: 'getproducts', Id: id, langId: this.globals.LANG_ID };

    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getproducts;
    }));
  }

  getProductByBusiness(id, type, preorder) {
    const parameters = { f: 'getproductbybusiness', Id: id, langId: (this.globals.LANG_ID > 0) ? this.globals.LANG_ID : 1, type: type, preorder: preorder };

    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getproductbybusiness;
    }));
  }

  getPhotoByBusiness(id) {
    const parameters = { f: 'businessphoto', Id: id };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.businessphoto;
    }));
  }

  getReviewByBusiness(id, limit) {
    const parameters = { f: 'businessreview', Id: id, limit: limit };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.businessreview;
    }));
  }
  setReviewByBusiness(id, quality, punctuality, service, packing, username, useremail, usercomments) {
    const parameters = { f: 'addbusinessreview', id: id, quality: quality, punctuality: punctuality, service: service, packing: packing, username: username, useremail: useremail, usercomments: usercomments };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.addbusinessreview;
    }));
  }
  setReviewByOrder(orderid, quality, punctuality, service, packing, username, useremail, usercomments) {
    const parameters = { f: 'addorderreview', orderId: orderid, quality: quality, punctuality: punctuality, service: service, packing: packing, username: username, useremail: useremail, usercomments: usercomments };

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.addorderreview;
    }));
  }

  getOfferByBusiness(id) {
    const parameters = { f: 'businessoffer', Id: id, langId: this.globals.LANG_ID};

    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.businessoffer;
    }));
  }

  getAllCountry() {
    const parameters = { f: 'fetchallcountry', langId: this.globals.LANG_ID };

    return this.http.get(Globals.API_ENDPOINT_COMMON, { params: parameters }).pipe(map((res: any) => {
      return res.fetchallcountry;
    }));
  }
  getCityByCountry(cid) {
    const parameters = { f: 'fetchcitybycountry', langId: this.globals.LANG_ID, cid: cid };

    return this.http.get(Globals.API_ENDPOINT_COMMON, { params: parameters }).pipe(map((res: any) => {
      return res.fetchcitybycountry;
    }));
  }
  setBusinessOwner(bform) {
    const parameters = { f: 'register', langId: this.globals.LANG_ID, firstname: bform.firstname, lastname: bform.lastname, email: bform.email, cel: bform.cel, bname: bform.bname, country: bform.country, city: bform.city, address: bform.address, location: JSON.stringify(bform.location), blocation: JSON.stringify(bform.blocation) };
    return this.http.get(Globals.API_ENDPOINT_BUSER, { params: parameters }).pipe(map((res: any) => {
      return res.register;
    }));
  }

  getOrderById(orderId) {
    const parameters = { f: 'fetchbyorder', orderId: orderId, langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchbyorder;
    }));
  }
  getUser(userId) {
    const parameters = { f: 'userbyid', userId: userId, langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.userbyid;
    }));
  }
  getUpdateUser(userDetails) {
    const parameters = { f: 'update', langId: this.globals.LANG_ID, userId: userDetails.id, name: userDetails.name, last_name: userDetails.last_name, email: userDetails.email, address: userDetails.address, cel: userDetails.cel, location: JSON.stringify(userDetails.location) };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.update;
    }));
  }
  setChangePass(userPass, userId) {
    const parameters = { f: 'changepassword', userId: userId, oldpass: userPass.oldpass, newpass: userPass.newpass };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.changepassword;
    }));
  }
  getFavBusiness(userId) {
    const parameters = { f: 'allfav', langId: this.globals.LANG_ID, userId: userId};
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.allfav;
    }));
  }
  deleteFavBusiness(bId) {
    const parameters = { f: 'deletefav', Id: bId };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.deletefav;
    }));
  }
  getOrderByUser(businessId, userId) {
    const parameters = { f: 'fetchorderbyuser', langId: this.globals.LANG_ID, userId: userId, bId: businessId };
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchorderbyuser;
    }));
  }
  setContact(contactDetails, bId) {
    const parameters = { f: 'contact', langId: this.globals.LANG_ID, name: contactDetails.name, email: contactDetails.email, message: contactDetails.message, bId: bId };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.contact;
    }));
  }
  setContactus(contactDetails) {
    const parameters = { f: 'contact', langId: this.globals.LANG_ID, name: contactDetails.name, email: contactDetails.email, message: contactDetails.message };
    return this.http.get(Globals.API_ENDPOINT_COMMON, { params: parameters }).pipe(map((res: any) => {
      return res.contact;
    }));
  }
  fetchUserByMD5(Id) {
    const parameters = { f: 'fetchusrbymd5', id: Id };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchusrbymd5;
    }));
  }
  UpdatePass(userId, pass) {
    const parameters = { f: 'updatepass', userId: userId, pass: pass};
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.updatepass;
    }));
  }
  fetchLinkByMD5(Id) {
    const parameters = { f: 'fetchlinkbymd5', id: Id };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.fetchlinkbymd5;
    }));
  }
  updateUserImage(file) {
    let head;
    head = {headers: this.headers};
    return this.http.post(Globals.API_ENDPOINT_UPLOAD, file, head).pipe(map((res: any) => {
      return res;
    }));
  }
  getSocialLogin(socialuser) {
    const parameters = { f: 'sociallogin', email: socialuser.email, name: socialuser.name, lastname: socialuser.lastname, image: socialuser.image, langId: this.globals.LANG_ID, type: socialuser.type };
    return this.http.get(Globals.API_ENDPOINT_USER, { params: parameters }).pipe(map((res: any) => {
      return res.sociallogin;
    }));
  }
  getAllCurrecny() {
    const parameters = { f: 'allcurrency'};
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.allcurrency;
    }));
  }
  getOtp(usercel, bid) {
    const parameters = { f: 'otpsend', usercel: usercel, bid: bid};
    return this.http.get(Globals.API_ENDPOINT_PLACE, { params: parameters }).pipe(map((res: any) => {
      return res.otpsend;
    }));
  }
  getOtpVerified(otp, oid) {
    const parameters = { f: 'otpverified', otp: otp, oid: oid};
    return this.http.get(Globals.API_ENDPOINT_PLACE, { params: parameters }).pipe(map((res: any) => {
      return res.otpverified;
    }));
  }
  getBookById(bookId) {
    const parameters = { f: 'fetchbybooking', bookId: bookId, langId: this.globals.LANG_ID };
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchbybooking;
    }));
  }
  getBookingByUser(businessId, userId) {
    const parameters = { f: 'fetchbookingbyuser', langId: this.globals.LANG_ID, userId: userId, bId: businessId };
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchbookingbyuser;
    }));
  }
  getRewardByUser(businessId, userId) {
    const parameters = { f: 'fetchrewardbyuser', langId: this.globals.LANG_ID, userId: userId, bId: businessId };
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.fetchrewardbyuser;
    }));
  }
  checkFirstData(storename, sharedSecret, currency, chargetotal, timezone) {
    const parameters = { f: 'checkfirstdata', storename: storename, sharedSecret: sharedSecret, currency: currency, chargetotal: chargetotal, timezone: timezone };
    return this.http.get(Globals.API_ENDPOINT_PAYMENT, { params: parameters }).pipe(map((res: any) => {
      return res.checkfirstdata;
    }));
  }
  getCheck() {
    return this.http.get(Globals.API_ENDPOINT_FIRSTDAT_PAYMENT_FIRSTDATA).pipe(map((res: any) => {
      return res;
    }));
  }
  getCheckFirstOffer(businessId, userId) {
    const parameters = { f: 'checkfirstorder', bId: businessId, uId: userId };
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.checkfirstorder;
    }));
  }
  cancelOrder(id) {
    const parameters = { f: 'cancelorder', orderId: id, langId: this.globals.LANG_ID};
    return this.http.get(Globals.API_ENDPOINT_ORDER, { params: parameters }).pipe(map((res: any) => {
      return res.cancelorder;
    }));
  }
  paypalPayment(orderID, ptype, clientid, secretkey, txnID) {
    const parameters = { f: 'chargePaypal', orderId: orderID, paymentType: ptype, clientId: clientid, secretKey: secretkey, txnId: txnID};
    return this.http.get(Globals.API_ENDPOINT_PAYMENT, { params: parameters }).pipe(map((res: any) => {
      return res.chargePaypal;
    }));
  }
  stripePaymentIntent(orderamount, businesscurrency, secretkey) {
    const parameters = { f: 'paymnetIntent', orderAmount: orderamount, businessCurrency: businesscurrency, secretKey: secretkey};
    return this.http.get(Globals.API_ENDPOINT_PAYMENT, { params: parameters }).pipe(map((res: any) => {
      return res.paymnetIntent;
    }));
  }
  getBusinessMenu(bid, ordertype, preorderdetails) {
    const parameters = { f: 'getbusinessmenu', bId: bid, langId: this.globals.LANG_ID, orderType: ordertype, preorderDetails: preorderdetails };
    return this.http.get(Globals.API_ENDPOINT_BUSINESS, { params: parameters }).pipe(map((res: any) => {
      return res.getbusinessmenu;
    }));
  }
  getProductByCategory(dishid, orderType) {
    const parameters = { f: 'getproductbycategory', langId: this.globals.LANG_ID,  dishId: dishid, type: orderType};
    return this.http.get(Globals.API_ENDPOINT_PRODUCT, { params: parameters }).pipe(map((res: any) => {
      return res.getproductbycategory;
    }));
  }
  CreateRzpayOrder(data: any) {
    const parameters = { f: 'CreateRzpayOrder', amount: data.amount, currency: data.currency, RAZORPAY_KEY_ID: data.RAZORPAY_KEY_ID,RAZORPAY_KEY_SECRET: data.RAZORPAY_KEY_SECRET};
    return this.http.get(Globals.API_ENDPOINT_PAYMENT, { params: parameters }).pipe(map((res: any) => {
      return res.rzpay_orderid;
    }));
  }
}
