<section id="colse-business">
  <div class="colse-business-body">
      <span>
          <img [lazyLoad]="closeImg" />
      </span>
      <h4>{{language.SORRY}}</h4>
      <h5>{{language.RENEWAL_EXPIRES}}</h5>
      <p>{{language.STAY_TUNED}}</p>
  </div>
</section>
